import React from 'react';

const TermsConditions = () => {
  return (
    <div className="container mb-5 pt-1">
      <h1 className="text-center mb-5 pt-5">Terms & Conditions</h1>
      <p>* By accepting these Terms & Conditions, You agree to be legally bound by the following terms & conditions.</p>

      <p>1. It is mandatory for all the guests to present valid photo identification at the time of check-in. According to government regulations, a valid Photo ID has to be carried by every person above the age of 18. Unmarried couples are strictly not allowed. Guests residing in the same city are not allowed.</p>

      <p>2. Please note that this might be the Dharmshala / Bhavan / Ashram / Sanatorium. Yatris has to accept & comply with all their rules/terms & conditions to ensure that all can have a stay truly to a very sanctimonious spiritual experience for all.</p>

      <p>3. Dharmshala / Bhavan / Ashram / Sanatorium management has also the right of refusal of entry if the booking is in violation of their rules. No refund would be applicable in case they deny check-in under such circumstances.</p>

      <p>4. The standard check-in time and check-out time is mentioned on the page. Please make your reservation accordingly as early check-in or late check-out might not be possible.</p>

      <p>5. Please note that almost all the rooms/halls have a certain capacity for the guests/room, it is mentioned on the page. This limit is strictly followed.</p>

      <p>6. There are Dharmshala / Bhavan / Ashram / Sanatorium which can be booked only for the Yatris following principles of a certain religion. It is clearly mentioned for each room type.</p>

      <p>7. As of now our system is not allowing users to make any modifications/cancellations of the booking.</p>

      <p>8. A booking confirmation is an acknowledgement confirming that we have received your request for accommodation. However, it is subject to Dharmshala management’s modification/cancellation in case of any unfavourable situation, and the ATITHIBHAVAN.COM management cannot be held responsible or liable for any such changes.</p>

      <p>9. Any dispute arising out of this service shall be subject to Bhavnagar (Gujarat) jurisdiction.</p>

      <p>10. We may change these terms at any time by posting notifications online</p>

      <p>11. Please note that in case of any incident/event, our liability is limited to the convenience fee only. Our services are provided to the best of our ability and the information we have received from others. Any information, opinion, statement, recommendation or anything whatsoever shall not form a guarantee. Whilst we can advise you but it is your responsibility to ensure that products and services meet your particular needs.7/*-.</p>

      <p>12. Conduct and Respect:
   <li> Guests are expected to conduct themselves in a respectful manner, adhering to the temple's guidelines and principles.</li>
   <li> Loud noises, disruptive behavior, and activities contrary to the temple's ethos are strictly prohibited.</li></p>

      <p>13. Prohibited Items:
      <li> The possession of illegal substances, weapons, or any items deemed inappropriate by the temple authorities is strictly prohibited.</li></p>

      <p>14. Booking Process:
      <li> All room bookings are subject to availability.</li>
      <li> Booking confirmation will be sent to the email address provided during the reservation process.</li>
      <li> Only individuals aged 18 and above are eligible to make a booking.</li></p>

      <p> WWW.ATITHIBHAVAN.COM is not endorsing or promoting any particular place or religion. We are working to promote religious tourism</p>

      <p>16. Contact Information:
      <li> For inquiries or assistance, please contact Support@atithibhavan.com.</li></p>

    </div>
  );
};

export default TermsConditions;

// --- ATITHI BHAVAN ---
// Terms & Conditions

// By accepting these Terms & Conditions, You agree to be legally bound by the following terms & conditions.
// -----------------------------------------------------------------------------------------------------------------
// 1.  It is mandatory for all the guests to present valid photo identification at the time of check-in. According to government regulations, a valid Photo ID has to be carried by every person above the age of 18. Unmarried couples are strictly not allowed. Guests residing in the same city are not allowed.

// 2.  Please note that this might be the Dharmshala / Bhavan / Ashram / Sanatorium. Yatris has to accept & comply with all their rules/terms & conditions to ensure that all can have a stay truly to a very sanctimonious spiritual experience for all.

// 3.  Dharmshala / Bhavan / Ashram / Sanatorium management has also the right of refusal of entry if the booking is in violation of their rules. No refund would be applicable in case they deny check-in under such circumstances.

// 4.  The standard check-in time and check-out time is mentioned on the page. Please make your reservation accordingly as early check-in or late check-out might not be possible.

// 5.  Please note that almost all the rooms/halls have a certain capacity for the guests/room, it is mentioned on the page. This limit is strictly followed.

// 6.  There are Dharmshala / Bhavan / Ashram / Sanatorium which can be booked only for the Yatris following principles of a certain religion. It is clearly mentioned for each room type.

// 7. As of now our system is not allowing users to make any modifications/cancellations of the booking. 

// 8.  A booking confirmation is an acknowledgement confirming that we have received your request for accommodation. However, it is subject to Dharmshala management’s modification/cancellation in case of any unfavourable situation, and the ATITHIBHAVAN.COM management cannot be held responsible or liable for any such changes.

// 9.  Any dispute arising out of this service shall be subject to Bhavnagar (Gujarat) jurisdiction.

// 10.  We may change these terms at any time by posting notifications online.

// 11.  Please note that in case of any incident/event, our liability is limited to the convenience fee only. Our services are provided to the best of our ability and the information we have received from others. Any information, opinion, statement, recommendation or anything whatsoever shall not form a guarantee. Whilst we can advise you but it is your responsibility to ensure that products and services meet your particular needs.7/*-.

// 12. Conduct and Respect:
//    - Guests are expected to conduct themselves in a respectful manner, adhering to the temple's guidelines and principles.
//    - Loud noises, disruptive behavior, and activities contrary to the temple's ethos are strictly prohibited.

// 13. Prohibited Items:
//    - The possession of illegal substances, weapons, or any items deemed inappropriate by the temple authorities is strictly prohibited.

// 14. Booking Process:
//    - All room bookings are subject to availability.
//    - Booking confirmation will be sent to the email address provided during the reservation process.
//    - Only individuals aged 18 and above are eligible to make a booking.

// 15.  WWW.ATITHIBHAVAN.COM is not endorsing or promoting any particular place or religion. We are working to promote religious tourism

// 16. Contact Information:
//    - For inquiries or assistance, please contact Support@atithibhavan.com.