import React from "react";
import "./Home.css";
import TopDestinations from "../components/TopDestinations";
import Trending from "../components/Trending";
import ExploreGujarat from "../components/ExploreGujarat";

const Home = () => {
  return (
    <div className="home">
      <div className="container pt-5 mt-3">
        <ExploreGujarat />
        <TopDestinations />
        <Trending />
      </div>
    </div>
  );
};

export default Home;