import React, { useState, useEffect } from 'react'
import ReactPaginate from 'react-paginate'
import Temple from './Temple';

const TempleSearchResult = ({ citymandir }) => {
    const [currentPage, setCurrentPage] = useState(0);
    const [currentPageData, setCurrentPageData] = useState([])

    const PER_PAGE = 12;
    const pageCount = Math.ceil(citymandir.length / PER_PAGE);
    const offset = currentPage * PER_PAGE;

    useEffect(() => {
        if (citymandir) {
            setCurrentPageData(citymandir
                .slice(offset, offset + PER_PAGE)
                .map((data) => <Temple {...data} logo={false} />));
        }
    }, [citymandir, offset])


    function handlePageClick({ selected: selectedPage }) {
        setCurrentPage(selectedPage);
    }

    return (
        <div>
            <h3 className='pb-3'>{citymandir?.length || 0} Temple found</h3>
            <div className='row'>
                {currentPageData}
                <div className='row pt-1 pt-md-5 mt-4'>
                    <nav>
                        <ReactPaginate
                            previousLabel={<span aria-hidden="true"> {"<"} </span>}
                            nextLabel={<span aria-hidden="true"> {">"} </span>}
                            pageCount={pageCount}
                            onPageChange={handlePageClick}
                            containerClassName={"pagination pagination-lg justify-content-center"}
                            pageClassName={"page-item"}
                            pageLinkClassName={"page-link border-0 text-secondary"}
                            previousClassName={"page-item"}
                            previousLinkClassName={"page-link border-0 text-secondary"}
                            nextClassName={"page-item"}
                            nextLinkClassName={"page-link border-0 text-secondary"}
                            disabledClassName={"pagination__link--disabled"}
                            activeClassName={"pagination__link--active active"}
                            activeLinkClassName='active-btn'
                        />
                    </nav>
                    <div className='text-secondary text-center'>{offset + 1} - {currentPageData.length < PER_PAGE ? citymandir.length : offset + PER_PAGE} of {citymandir.length} Hotels</div>
                </div>
            </div>
        </div>

    )
}

export default TempleSearchResult