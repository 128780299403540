import React from 'react'
import Destination from './Destination'
// import sarangpur_des from "../images/gujarat/sarangpur_des.png";
// import nilkanth_des from "../images/gujarat/Nilkanth_Dham_des.png";
// import junagadh_des from "../images/gujarat/junagadh_des.png";
// import kutch_des from "../images/gujarat/kutch_des.png";
// import rajkot_des from "../images/gujarat/rajkot_des.png";
// import somanath_des from "../images/gujarat/somanath_des.png";
import { useDispatch, useSelector } from 'react-redux';
import api from '../api';
import { useEffect } from 'react';
import { updateMandir } from '../store/mandir';

// const destinations = [
//     {
//         image: sarangpur_des,
//         name: "Sarangpur",
//         option: {
//             activites: 21,
//             cars: 15,
//             hotels: 20,
//             rentals: 19,
//             tours: 21
//         }
//     },
//     {
//         image: nilkanth_des,
//         name: "Nilkanth Dham",
//         option: {
//             activites: 21,
//             cars: 20,
//             hotels: 20,
//             rentals: 19,
//             tours: 22
//         }
//     },
//     {
//         image: somanath_des,
//         name: "Somnath",
//         option: {
//             activites: 21,
//             cars: 14,
//             hotels: 17,
//             rentals: 19,
//             tours: 21
//         }
//     },
//     {
//         image: rajkot_des,
//         name: "Rajkot",
//         option: {
//             activites: 21,
//             cars: 14,
//             hotels: 20,
//             rentals: 20,
//             tours: 21
//         }
//     },
//     {
//         image: kutch_des,
//         name: "Kutch",
//         option: {
//             activites: 21,
//             cars: 14,
//             hotels: 20,
//             rentals: 19,
//             tours: 21
//         }
//     },
//     {
//         image: junagadh_des,
//         name: "Junagadh",
//         option: {
//             activites: 21,
//             cars: 14,
//             hotels: 17,
//             rentals: 19,
//             tours: 21
//         }
//     },
// ]
const TopDestinations = () => {
    const dispatch = useDispatch();
    const token = useSelector((state) => state.user.token);
    const mandir = useSelector((state) => state.mandir.mandir);
    useEffect(() => {
        api.mandir.list().then(res=>{
            if (res.data?.data) {
                dispatch(updateMandir(res.data.data))
            }
        })
    }, [token, dispatch]);

    return (
        <div className="row pt-5 justify-content-center">
            <div className="dark-font d-flex justify-content-center mb-4">
                Top Destinations
            </div>
            <div className="row">
                {mandir?.slice(0, 6).map((element, i) => <div className="col-md-4 py-3" key={i}>
                    <Destination element={element} />
                </div>)}
            </div>
        </div>
    )
}

export default TopDestinations