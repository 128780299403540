import React from 'react'
import {  useNavigate } from 'react-router-dom';
import { BASE_URL } from "../api";

const Destination = ({element}) => {
    const navigate = useNavigate()

    return (
        // <div className="temple-img-des position-relative" onClick={()=>navigate(`/mandirDetail?id=`+ element._id)}>
            
        //     <img src={element.image} alt={element.mandirName} width="100%" />
        //     <div className="opacity"></div>
        //     <div className="row justify-content-center position-absolute bottom-0 ">
        //         <h3 className="text-light fw-medium display-title">
        //             {element.mandirName}
        //         </h3>
        //         <ul className="d-flex flex-wrap text-white justify-content-center px-md-0 px-lg-4 px-xl-5 px-4">
        //             <span className="pe-3 ms-3">{element.activities} Activities</span>
        //             <li className="pe-3 ms-3">{element.cars} Cars</li>
        //             <li className="pe-3 ms-3">{element.hotels} Hotels</li>
        //             <li className="pe-3 ms-3">{element.rentals} Rentals</li>
        //             <li className="pe-3 ms-3">{element.tours} Tours</li>
        //         </ul>
        //     </div>
        // </div>
            <div className="temple-img-des position-relative" onClick={()=>navigate(`/mandirDetail?id=`+ element.id)}>
                <img src={BASE_URL + "images/" + element.image} alt={element.mandirName} width="100%" height="357px" />
                <div className="opacity: 0.2"></div>
                <div className="row justify-content-center position-absolute bottom-0 ">
                    <h3 className="text-light text-center">
                        {element.mandirName}
                    </h3>
                    <ul className="d-flex flex-wrap text-white justify-content-center px-md-0 px-lg-4 px-xl-5 px-4">
                        <span className="pe-3 ms-3">{element.activities} Activities</span>
                        <li className="pe-3 ms-3">{element.cars} Cars</li>
                        <li className="pe-3 ms-3">{element.hotels} Hotels</li>
                        <li className="pe-3 ms-3">{element.rentals} Rentals</li>
                        <li className="pe-3 ms-3">{element.tours} Tours</li>
                    </ul>
                </div>
            </div>
        )
}

export default Destination