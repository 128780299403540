import React, { useEffect, useState } from "react";
import { useWindowSize } from '../hooks';
// import junagadh from "../images/gujarat/junagadh.png";
// import path from "../../../backend/public/images/surat.png";
// import rajkot from "../images/gujarat/rajkot.png";
// import kutch from "../images/gujarat/kutch.png";
// import somnath from "../images/gujarat/somnath.png";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Autoplay, Scrollbar } from 'swiper/modules';
import { useDispatch, useSelector } from "react-redux";
import { updateCity } from "../store/city";
import api, { BASE_URL } from "../api";
import { useNavigate } from "react-router-dom";


const ExploreGujarat = () => {
    const windowSize = useWindowSize();
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const token = useSelector((state) => state.user.token);
    const city = useSelector((state) => state.city.city);

    useEffect(() => {
        api.city.list().then(res=>{
            if (res.data?.data) {
                dispatch(updateCity(res.data.data))
            }
        })
    }, [token, dispatch])

    useEffect(()=>{
        console.log("cities", city);
    },[city])

    const [noOfCards, setNoOfCards] = useState(5);
    // const explore = [
    //     {
    //         name: "Junagadh",
    //         image: junagadh
    //     },
    //     {
    //         name: "Surat",
    //         image: surat
    //     },
    //     {
    //         name: "Rajkot",
    //         image: rajkot
    //     },
    //     {
    //         name: "Kutch",
    //         image: kutch
    //     },
    //     {
    //         name: "Somnath",
    //         image: somnath
    //     },
    //     {
    //         name: "Junagadh",
    //         image: junagadh
    //     },
    //     {
    //         name: "Surat",
    //         image: surat
    //     },
    //     {
    //         name: "Rajkot",
    //         image: rajkot
    //     },
    //     {
    //         name: "Kutch",
    //         image: kutch
    //     },
    //     {
    //         name: "Somnath",
    //         image: somnath
    //     },
    //     {
    //         name: "Kutch",
    //         image: kutch
    //     },
    //     {
    //         name: "Somnath",
    //         image: somnath
    //     }
    // ]

    useEffect(() => {
        const a = [
            {
                width: 768,
                cards: 1
            },
            {
                width: 992,
                cards: 3
            }
        ]
        const noCards = a.find((w) => windowSize.width < w.width)?.cards || 5;
        setNoOfCards(noCards);
    }, [windowSize.width])

    return (
        <div className="mb-4">
            <div className="dark-font">Explore Gujarat</div>
            <div className="light-font" style={{ color: "#474747" }}>
                These popular destinations have a lot to offer
            </div>
            <Swiper
                slidesPerView={noOfCards}
                spaceBetween={30}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                }}
                scrollbar={{ draggable: true }}
                modules={[Autoplay, Scrollbar]}
                className="mySwiper pt-3"
            >
                {city?.map((element, i) => <SwiperSlide key={i}>
                    <div className="temple-img" onClick={() => navigate('mandir?id=' + element.id)}>
                    <img src={BASE_URL + "images/" + element.image} alt={element.cityName} width="100%" height="193.538px"/>
                    </div>
                    <div className="title">{element.cityName}</div>
                </SwiperSlide>)}
            </Swiper>
        </div>
    )
}

export default ExploreGujarat