import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="container mb-5 pt-1">
      <h1 className="text-center mb-5 pt-5">Privacy Policy</h1>

      <p>Atithi Bhavan Temple Room Booking Website ("us," "we," or "our") is committed to protecting the privacy of our users. This Privacy Policy outlines the information we collect, how we use it, and the choices you have regarding your personal information. By using our website, you agree to the terms of this Privacy Policy.</p>

    <h5 className="mb-2 pt-2">Information We Collect:</h5>
      <p>1. *Personal Information:*
      <li> When making a room reservation, we collect personal information such as your name, contact details, and payment information.</li></p>

      <p>2. *Log Data:*
      <li> We collect information that your browser sends whenever you visit our website ("Log Data"). This Log Data may include information such as your computer's Internet Protocol ("IP") address, browser type, browser version, the pages of our website that you visit, the time and date of your visit, and other statistics.</li></p>

      <p>3. *Cookies:*
      <li> We use cookies to collect information and improve our services. Cookies are small files stored on your device that contain data specific to you.</li></p>

    <h5 className="mb-2 pt-2">How We Use Your Information:</h5>
      <p>1. *Room Reservations:*
      <li> We use your personal information to process and confirm your room reservation.</li></p>

      <p>2. *Improving Services:*
      <li> We may use information, such as Log Data and cookies, to analyze trends, administer the website, track users' movements, and gather demographic information.</li></p>

      <p>3. *Communication:*
      <li> We may use your contact information to communicate with you about your reservation, provide updates, and respond to inquiries.</li></p>

      <p>4. *Legal Compliance:*
      <li> We may use your information to comply with legal obligations, resolve disputes, and enforce our agreements.</li></p>

    <h5 className="mb-2 pt-2">Information Sharing:</h5>
      <p>1. *Third-Party Service Providers:*
      <li> We may share your personal information with third-party service providers who assist us in providing and improving our services.</li></p>

      <p>2. *Legal Requirements:*
      <li> We may disclose your information in response to a subpoena, court order, or other governmental request.</li></p>

      <p>3. *Communication:*
      <li> We may use your contact information to communicate with you about your reservation, provide updates, and respond to inquiries.</li></p>

      <p>4. *Legal Compliance:*
      <li> We may use your information to comply with legal obligations, resolve disputes, and enforce our agreements.</li></p>

      <h5 className="mb-2 pt-2">*Security:*</h5>
      <p>We take reasonable measures to protect the security and confidentiality of your personal information. However, no method of transmission over the internet or electronic storage is 100% secure</p>

      <h5 className="mb-2 pt-2">*Your Choices:*</h5>
      <p>You have the right to:
      <li> Access, correct, or delete your personal information.</li>
      <li> Opt-out of receiving promotional communications.</li></p>

      <h5 className="mb-2 pt-2">*Changes to this Privacy Policy:*</h5>
      <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.</p>

      <h5 className="mb-2 pt-2">*Contact Us:*</h5>
      <p>If you have any questions about this Privacy Policy, please contact us at support@atithibhavan.com.</p>
      <p>By using the Atithi Bhavan Temple Room Booking Website, you agree to the collection and use of information in accordance with this policy.</p>

    </div>
  );
};

export default PrivacyPolicy;