import React, { useState } from "react";
import ReactDatePicker from "react-datepicker";
import ownerIcon from '../images/roomDetail/owner.png'
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import { addDays } from 'date-fns';

export default function RoomBook({roomId}) {
    const roomDetail = useSelector((state) => state.room.roomdetails);
    const initialStartDate = new Date();
  const initialEndDate = addDays(initialStartDate, 1)
    const [roomDateRange, setRoomDateRange] = useState([new Date(), initialEndDate]);
    const [roomDateStart, roomDateEnd] = roomDateRange;
    const timeDifference = roomDateEnd - roomDateStart;
    const daysDifference = timeDifference / (1000 * 60 * 60 * 24);
    localStorage.setItem("days", daysDifference)
    const navigate = useNavigate()
    const [adult, setAdult] = useState("")
    const hello = () => {
        localStorage.setItem("roomDateRange", roomDateRange)
        localStorage.setItem("adult", adult)
        navigate('/checkout')
        window.scrollTo(0, 0)
      }

    return (
        <>
            <div className="card card-form">
                <div className="p-3 form-head">from <span className='price'>₹{roomDetail.price}</span>/1 nights</div>
                <div className='p-3 bt-1'>
                    <div className="checkin">Check In - Out</div>
                    <ReactDatePicker
                        selectsRange={true}
                        startDate={roomDateStart}
                        endDate={roomDateEnd}
                        onChange={(update) => {
                            setRoomDateRange(update);
                        }}
                        withPortal
                        dateFormat="dd/MM/yyyy"
                    />
                </div>
                <div>
                    <form className="form-floating">
                        <select className="form-select field guestSelectorTemple" id='guestSelectorRoom' aria-label="Default select example" onChange={(e) => setAdult(e.target.value)}>
                            <option selected value="1">1 Adult - 0 Children</option>
                            <option value="2">2 Adult - 0 Children</option>
                            <option value="1">1 Adult - 1 Children</option>
                            <option value="1">1 Adult - 2 Children</option>
                            <option value="2">2 Adult - 1 Children</option>
                            <option value="2">2 Adult - 2 Children</option>
                        </select>
                        <label for="guestSelectorRoom"><p>Guest</p></label>
                    </form>
                </div>
                {/* <div className="p-3 text-end">
                    <div className="btn-group">
                        <div type="button" className="field dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                            More Option
                        </div>
                        <ul className="dropdown-menu px-2">
                            <li>Action</li>
                            <li>Another action</li>
                            <li>Something else here</li>
                        </ul>
                    </div>
                </div> */}
                <div className="p-3 text-center bt-1">
                    <button type="button" className="btn btn-primary" onClick={hello}>Book Now</button>
                </div>
            </div>
            <div className="card card-owner">
                <div className="title">Owner</div>
                <div className="d-flex flex-wrap align-items-center">
                    <div className='p-2' ><img src={ownerIcon} alt="owner" /></div>
                    <div className='p-2' >
                        <div className='logo-name mb-2'>Traveler</div>
                        <div className='afflimate'>Member Since 2018</div>
                    </div>
                </div>
                <div className='text-center px-4 px-md-0'>
                    <button type="button" className="btn btn-primary">Ask a Question</button>
                </div>
            </div>
        </>
    )
}