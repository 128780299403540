import React, { useState, useEffect } from 'react'
// import mapview from "../images/temple/mapview.png"
// import orangestar from "../images/gujarat/orangestar.png"
import "./Mandir.css";
import { SlLocationPin } from 'react-icons/sl'
import { useNavigate } from "react-router-dom";
// import { CiCalendarDate } from 'react-icons/ci'
// import { BsPeople } from 'react-icons/bs'
// import ReactDatePicker from 'react-datepicker'
import TempleSearchResult from '../components/TempleSearchResult';
// import DoubleSlider from '../components/DoubleSlider';
// import CustomDropdown from '../components/CustomDropdown';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import api from '../api';
import { cityMandir } from '../store/mandir';
import Swal from 'sweetalert2';

// const MINRANGE = 399;
// const MAXRANGE = 15000;

const Mandir = () => {
  // const mySlider = useRef();
  // const [templeDateRange, setTempleDateRange] = useState([new Date(), new Date()]);
  // const [templeDateStart, templeDateEnd] = templeDateRange;
  const search = useLocation().search;
  const id = new URLSearchParams(search).get("id");
  const [location, setLocation] = useState("")
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = useSelector((state) => state.user.token);
  const Citymandir = useSelector((state) => state.mandir.cityMandir);
  const cities = useSelector((state) => state.city.city);
  const [data, setData] = useState(Citymandir);
  const [name, setDestination] = useState('');

  const handleSearch = async () => {
    try {
      const response = await api.mandir.search({name});
      if (response.data.data && response.data.data.length > 0) {
        console.log(response.data.data);
        navigate('/mandirDetail?id=' + response.data.data[0].id);
      } else {
        console.warn('No data found in the response');
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Temple not found',
      })
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleSearch();
  };
  // const [starStatus] = useState([
  //   {
  //     name: 5,
  //     checked: false,
  //   },
  //   {
  //     name: 4,
  //     checked: false,
  //   },
  //   {
  //     name: 3,
  //     checked: false,
  //   },
  //   {
  //     name: 2,
  //     checked: false,
  //   },
  //   {
  //     name: 1,
  //     checked: false,
  //   },
  // ])
  // const [priceRange, setPriceRange] = useState([MINRANGE, MAXRANGE]);
  // const [facilateStatus, setFacilateStatus] = useState([
  //   {
  //     name: "Air Conditioning",
  //     checked: false
  //   },
  //   {
  //     name: "Airport Transport",
  //     checked: false
  //   },
  //   {
  //     name: "Fitness Center",
  //     checked: false
  //   },
  // ]);

  // const [themeStatus, setThemeStatus] = useState([
  //   {
  //     name: "Best value",
  //     checked: false
  //   },
  //   {
  //     name: "Budget",
  //     checked: false
  //   },
  //   {
  //     name: "Budget",
  //     checked: false
  //   },
  // ]);

  useEffect(() => {
    api.mandir.cityWise(id).then(res => {
      if (res.data?.data) {
        dispatch(cityMandir(res.data.data))
      }
    })
    const index = cities.findIndex(i => i.id === id);
    if (index !== -1) {
      const city = cities[index]
      setLocation(`${city.cityName}, ${city.stateName}`)
    }
  }, [token, dispatch, id, cities])


  useEffect(() => {
    setData(Citymandir.filter((element) => {
      let result = true
      // if (element.amount < priceRange[0] || element.amount > priceRange[1]) {
      //   result = result && false
      // }
      // const checkfacilites = facilateStatus.reduce((prev, curr) => curr.checked || prev, false)
      // if (checkfacilites) {
      //   const filteredFacilites = facilateStatus.filter((e) => e.checked);
      //   result = result && filteredFacilites.map(e => e.name).includes(element.facilities)
      // }
      // const checkthemes = themeStatus.reduce((prev, curr) => curr.checked || prev, false)
      // if (checkthemes) {
      //   const filteredFacilites = themeStatus.filter((e) => e.checked);
      //   result = result && filteredFacilites.map(e => e.name).includes(element.temple_theme)
      // }
      return result;
    }))
  }, [ Citymandir])

  // const changeCheck = (starStatus, setStarStatus, i) => {
  //   setStarStatus([...starStatus.slice(0, i), { name: starStatus[i].name, checked: !starStatus[i].checked }, ...starStatus.slice(i + 1, starStatus.length)])
  // }

  return (
    <div className='container pt-4 pb-md-5 mb-md-5'>
      <div className='row mandir pb-md-5 mb-md-5'>
        <div className='col-md-4'>
          <div className="card card-form">
            <div className="p-2 p-md-2 p-lg-3 pt-4">
              <div style={{ borderLeft: "4px solid #5191FA" }}>
                <div className="title ps-3" style={{ color: "#1A2B48" }}>Search Temple</div>
              </div>
            </div>
            <hr />
            <div className='px-3 px-md-2 px-lg-3 d-flex align-items-center'>
              <SlLocationPin />
              <div className='ps-3 w-100'>
                <div className="checkin">Temple</div>
                <form onSubmit={handleSubmit} >
                <input
                 type="text"
                 placeholder="Enter Temple Name"
                 style={{
                  border: 'none',
                  width: '100%',
                   borderBottom: '1px solid #ced4da',
                  borderRadius: '0',
                   outline: 'none'
                 }}
                 value={name}
                 onChange={(e) => setDestination(e.target.value)}
                />
                </form>
              </div>
            </div>
            <div className="p-3 text-center">
            <button type="button" className="btn button1 btn-primary" onClick={handleSearch}
            >Search</button>
            </div>
          </div>
          <div
            className='pb-3'
            style={{ minHeight: "200px" }}
          >
            <div class="mapouter">
              <div class="gmap_canvas">
                <iframe
                  title="map"
                  id="gmap_canvas"
                  className='responsive-iframe'
                  style={{ minHeight: "250px", width: "100%" }}
                  src={`https://maps.google.com/maps?q=${location.replaceAll(" ", "+")}&t=&z=13&ie=UTF8&iwloc=&output=embed`}
                  frameborder="0"
                  scrolling="no"
                  marginheight="0"
                  marginwidth="0"
                >
                </iframe>
                {/* <a href="https://www.tabclocktab.com/">clock tab</a><br /><a
                  href="https://www.clock-alarm.com/"
                >
                </a> */}
                {/* <br />
                <a href="https://www.ongooglemaps.com">google maps embed iframe</a> */}
              </div>
            </div>
          </div>
          {/* <div className='mt-3 mt-md-5 mb-4' style={{ borderLeft: "4px solid #5191FA" }}>
            <div className="text-500 ps-3" style={{ color: "#1A2B48" }}>FILTER BY</div>
          </div>
          <div className='card p-4 mb-3' ref={mySlider} style={{ borderRadius: "8px" }} >
            <div className="accordion accordion-flush" id="accordionFlushExample">
              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-headingOne">
                  <button className="px-0 text-500 accordion-button collapsed" style={{ background: "transparent", boxShadow: "none" }} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                    Filter Price
                  </button>
                </h2>
                <div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                  <div className="accordion-body px-0 pt-5">
                    <DoubleSlider priceRange={priceRange} setPriceRange={setPriceRange} minRange={MINRANGE} maxRange={MAXRANGE} mySlider={mySlider} />
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          {/* <div className='card p-4 mb-3' style={{ borderRadius: "8px" }}>
            <div className="accordion accordion-flush" id="hotel">
              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-hotel">
                  <button className="px-0 text-500 accordion-button collapsed" style={{ background: "transparent", boxShadow: "none" }} type="button" data-bs-toggle="collapse" data-bs-target="#flush-hotel1" aria-expanded="false" aria-controls="flush-hotel1">
                    Hotel Star
                  </button>
                </h2>
                <div id="flush-hotel1" className="accordion-collapse collapse" aria-labelledby="flush-hotel" data-bs-parent="#hotel">
                  <div className="accordion-body px-0 pt-4">
                    {starStatus.map((element, i) => (<div key={i} className="form-check mb-2">
                      <input className="form-check-input boxborder" type="checkbox" onChange={(e) => changeCheck(starStatus, setStarStatus, i)} checked={element.checked} id={"flexCheckChecked" + element.name} />
                      <label className="form-check-label text-secondary" for={"flexCheckChecked" + element.name}>
                        <div className='d-flex' style={{ maxWidth: "15px" }}>
                          {Array(element.name).fill(null).map((element, i) => <img key={i} alt='orangestar' src={orangestar} width="100%" />)}
                        </div>
                      </label>
                    </div>))}
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          {/* <div className='card p-4 mb-3' style={{ borderRadius: "8px" }}>
            <div className="accordion accordion-flush" id="facilities">
              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-facilities">
                  <button className="px-0 text-500 accordion-button collapsed" style={{ background: "transparent", boxShadow: "none" }} type="button" data-bs-toggle="collapse" data-bs-target="#flush-facilities1" aria-expanded="false" aria-controls="flush-facilities1">
                    Facilities
                  </button>
                </h2>
                <div id="flush-facilities1" className="accordion-collapse collapse" aria-labelledby="flush-facilities" data-bs-parent="#facilities">
                  <div className="accordion-body px-0 pt-4">
                    {facilateStatus.map((element, i) => <div key={i} className="form-check mb-2">
                      <input className="form-check-input boxborder" onChange={(e) => changeCheck(facilateStatus, setFacilateStatus, i)} checked={element.checked} type="checkbox" id={"flexCheckChecked" + i} />
                      <label className="form-check-label text-secondary" for={"flexCheckChecked" + element.name}>
                        {element.name}
                      </label>
                    </div>)} */}
                    {/* <CustomDropdown /> */}
                  {/* </div>
                </div>
              </div>
            </div>
          </div> */}
          {/* <div className='card p-4' style={{ borderRadius: "8px" }}>
            <div className="accordion accordion-flush" id="theme">
              <div className="accordion-item">
                <h2 className="accordion-header" id="theme-temple">
                  <button className="px-0 text-500 accordion-button collapsed" style={{ background: "transparent", boxShadow: "none" }} type="button" data-bs-toggle="collapse" data-bs-target="#theme-collapse" aria-expanded="false" aria-controls="theme-collapse">
                    Temple Theme
                  </button>
                </h2>
                <div id="theme-collapse" className="accordion-collapse collapse" aria-labelledby="theme-temple" data-bs-parent="#theme">
                  <div className="accordion-body px-0 pt-4">
                    {themeStatus.map((element, i) => <div key={i} className="form-check mb-2">
                      <input className="form-check-input boxborder" type="checkbox" onChange={(e) => changeCheck(themeStatus, setThemeStatus, i)} checked={element.checked} id={"flexCheckChecked" + element.name} />
                      <label className="form-check-label text-secondary" for={"flexCheckChecked" + element.name}>
                        {element.name}
                      </label>
                    </div>)} */}
                    {/* <CustomDropdown /> */}
                   {/* </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
        <div className='col-md-8 pt-4 pt-md-0'>
          <TempleSearchResult citymandir={data} />
        </div>
      </div>
    </div>
  )
}

export default Mandir