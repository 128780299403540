import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    room: [],
    roomdetails:{}
}

export const roomSlice = createSlice({
    name: 'room',
    initialState,
    reducers: {
        updateRoom: (state, action) => { 
            state.room = action.payload;
        },
        getOneRoom:(state,action)=>{
            state.roomdetails = action.payload
        }
    },
})

// Action creators are generated for each case reducer function
export const { updateRoom,getOneRoom } = roomSlice.actions

export default roomSlice.reducer