import { createSlice } from '@reduxjs/toolkit'

const fetchToken = () => {
  return localStorage.getItem("token") || ""
}

const fetchUser = (token) => {
  if (token) {
    // After get User Profile API return
    return null
  }
  return null
}

const initialState = {
  token: fetchToken(),
  user: fetchUser(fetchToken()),
  userList: [],
  userdata: {},
  isAdmin: false
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    updateToken: (state, action) => {
      console.log("token", action.payload);
      localStorage.setItem("token", action.payload);
      state.token = fetchToken();
      state.user = fetchUser(action.payload)
    },
    getUser: (state, action) => {
      state.userdata = action.payload;
    },
    updateUser: (state, action) => {
      state.userList = action.payload;
    },
    setAdmin: (state, action) => {
      state.isAdmin = action.payload;
    }
  },
})

// Action creators are generated for each case reducer function
export const { updateToken, updateUser, getUser, setAdmin } = userSlice.actions

export default userSlice.reducer