import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    mandir: [],
    cityMandir:[]
}

export const mandirSlice = createSlice({
    name: 'mandir',
    initialState,
    reducers: {
        updateMandir: (state, action) => {
            state.mandir = action.payload;
        },
        cityMandir: (state,action) => {
            state.cityMandir = action.payload;
        }
    },
})

// Action creators are generated for each case reducer function
export const { updateMandir,cityMandir } = mandirSlice.actions

export default mandirSlice.reducer