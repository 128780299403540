import React, { useEffect, useState } from "react";
import { FaCheckCircle } from "react-icons/fa";
import item from "../images/gujarat/itemcard.png"
// import map from "../images/gujarat/map.png"
import Information from "../components/Information";
import PaymentItem from "../components/PaymentItem";
import { useSelector } from "react-redux";
import api from '../api';

const AfterCheckout = () => {
    const token = useSelector((state) => state.user.token);
    const checkout = useSelector((state) => state.checkout.checkout);
    const bookingId = localStorage.getItem("bookingId")
    const roomDetail = useSelector((state) => state.room.roomdetails)
    const totaldays = localStorage.getItem("days")
    const totalAmount = roomDetail.price*totaldays

    const [bookingDetail, setBookingDetail] = useState("");
    useEffect(() => {
         api.booking.getone(bookingId).then(res => {
            if (res.data && res.data.data) {
                setBookingDetail(res.data.data);}
        })
      }, [token, bookingId]);

      const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(dateString).toLocaleString(undefined, options);
      };

    const information=[
        {
            title:"First Name",
            value:checkout?.firstName || "",
            pt:true
        },
        {
            title:"Last Name",
            value: checkout?.lastName || ""
        },
        {
            title:"Email",
            value: checkout?.email || ""
        },
        {
            title:"Address Line 1",
            value: checkout?.address1 || ""
        },
        {
            title:"Address Line 2",
            value: checkout?.address2 || ""
        },
        {
            title:"City",
            value: checkout?.city || ""
        },
        {
            title:"State/Province/Region",
            value: checkout?.state || ""
        },
        {
            title:"ZIP code/Postal code",
            value: checkout?.zipcode || ""
        },
        {
            title:"Country",
            value: checkout?.country || ""
        },
        {
            title:"Special Requirements",
            value: checkout?.special_requirements || "",
            mb:true
        }
    ]

    const startDate = localStorage.getItem("roomDateRange").split(",")[0]
    const endDate = localStorage.getItem("roomDateRange").split(",")[1]
    const date1 = new Date(startDate)
    const date2 = new Date(endDate)

    const abc = date1.toLocaleDateString('en-GB', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
    })

    const cde = date2.toLocaleDateString('en-GB', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
    })
    const items =[
        {
            name: "Room",
            data: `${roomDetail.roomName}`
        },
        // {
        //     name:"Phone :",
        //     data:"+1 (543) 461-7550"
        // },
        {
            name:"Room :",
            data:"Double Room With Town View"
        },
        {
            name:"Number of rooms",
            data:"1"
        },
        {
            name:"Check In :",
            data:abc
        },
        {
            name:"Check Out :",
            data:cde
        },
        {
            name:"Price :",
            data:`₹${totalAmount}`
        },
        // {
        //     name:"Extra :",
        //     data:""
        // }
    ]

    return (
        <div className="container py-md-5 mt-5">
            <div className="row">
                <div className="col-md-8">
                    <div className="row align-items-center">
                        <div className="col-md-2 successicon col-3">
                            <FaCheckCircle style={{ width: "100%", height: "100%", fill: "#6AC259" }} />
                        </div>
                        <div className="col-md-10 col-9">
                            <div className="normal-font fw-bold" style={{ fontSize: '21px' }}>your order was submitted successfully!</div>
                            <div className="small-font text-secondary">Booking details has been sent to: <span className="text-dark fw-bold">{information.find(v=>v.title.toLocaleLowerCase()==="email").value}</span></div>
                        </div>
                    </div>
                    <div className="row px-2 mt-md-5 pt-3">
                        <div className="title pt-4 pb-3" style={{ color: "#1A2B48" }}>Your Information</div>
                        <div className="mx-md-2" style={{ border: "1px solid #D7DCE3", borderRadius: "8px" }}>
                            {information.map((element,i)=>{
                                 return <Information {...element}/>
                            })}
                        </div>
                    </div>
                </div>
                <div className="col-md-4 pt-3 pd-md-0">
                    <div style={{ borderLeft: "3px solid #5191FA" }} className="ps-4">
                        <div className="small-font fw-bold" >Booking Id: <span className="text-secondary">{bookingDetail.id}</span></div>
                        <div className="small-font py-2 fw-bold" >Booking Date: <span className="text-secondary">{formatDate(bookingDetail.createdAt)}</span></div>
                        <div className="small-font pb-2 fw-bold" >Payment Status: <span className="text-secondary">true</span></div>
                        <div className="small-font fw-bold" >Status: <span className="text-secondary">success</span></div>
                    </div>
                    <div className="row px-2 pt-md-4 pt-3">
                        <div className="title pb-3 pt-4" style={{ color: "#1A2B48" }}>Your Item</div>
                        <div className="mx-md-3" style={{ border: "1px solid rgb(215, 220, 227)", borderRadius: "8px" }}>
                            <div className="row p-4 align-items-center" style={{ borderBottom: "1px solid #EAEEF3" }}>
                                <div className="col-lg-8">
                                    <div className="text-500" style={{ color: "#1A2B48" }}>{roomDetail.hotelName}</div>
                                    {/* <div className="d-flex pt-2 align-items-center">
                                        <img alt="" src={map} style={{ maxWidth: "23px" }} />
                                        <div className="normal-font text-secondary ps-2"> New York City, NY, USA</div>
                                    </div> */}
                                </div>
                                <div className="col-lg-4">
                                    <img src={item} alt="item" width="100%" />
                                </div>
                            </div>
                            <div className="row p-4" style={{ color: "#1A2B48", borderBottom: "1px solid #EAEEF3" }}>
                                {items.map((value,index)=>{
                                    return <PaymentItem key={index} {...value} />
                                })}
                                <div className="pt-4" style={{ fontWeight: "700" }}>Guest Name: <span style={{ fontWeight: "400" }}>{checkout.firstName}</span></div>
                            </div>
                            <div className="row p-4 pb-5" style={{ color: "#1A2B48" }}>
                                {/* <div className="col-lg-5 pt-3 small-font">Discount</div> */}
                                {/* <div className="col-lg-7 pt-3 text-lg-end text-secondary small-font">50%</div> */}
                                <div className="col-lg-5 pt-3 small-font">Subtotal</div>
                                <div className="col-lg-7 pt-3 text-lg-end text-secondary small-font">₹{totalAmount}</div>
                                <div className="col-lg-5 pt-3 small-font" style={{ fontSize: "18px" }}>Pay Amount</div>
                                <div className="col-lg-7 pt-3 text-lg-end text-secondary small-font" style={{ fontSize: "18px" }}>₹{totalAmount}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AfterCheckout;