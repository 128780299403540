import React, { useEffect, useState} from "react";
import { FaFacebookF, FaLinkedinIn, FaYoutube } from "react-icons/fa";
import "./Header.css";
import Header_Footer_Logo from '../images/icons/Header_Footer_Logo.svg'
import basket from '../images/icons/basket.svg'
import c1 from "../images/gujarat/c1.png";
import c2 from "../images/gujarat/c2.png";
// import { SlLocationPin } from "react-icons/sl";
import { Link, useNavigate } from "react-router-dom";
// import { BsPeople } from "react-icons/bs";
// import { CiCalendarDate } from "react-icons/ci";
import { AiOutlineMenu } from "react-icons/ai";
import "./Detail.css";
// import ReactDatePicker from "react-datepicker";
import Login from "./Login";
import SignUp from "./SignUp";
import ForgetPassword from "./ForgetPassword";
import { useDispatch, useSelector } from 'react-redux';
import { getUser, updateToken } from '../store/user';
import api from "../api";
import { updateMandir } from "../store/mandir";
import { updateRoom } from "../store/room";
import { Carousel } from 'react-bootstrap';
import { GoSearch } from 'react-icons/go';
import Swal from 'sweetalert2';

const Header = ({ isImage = false, isTab }) => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.user.token);
  const user = useSelector((state) => state.user.userdata);
  const mandir = useSelector((state) => state.mandir.mandir);
  const room = useSelector((state) => state.room.room);
  const navigate = useNavigate();
  const isMobile = window.innerWidth <= 767;
  // const [templeDateRange, setTempleDateRange] = useState([new Date(), new Date()]);
  // const [templeDateStart, templeDateEnd] = templeDateRange;
  // const [roomDateRange, setRoomDateRange] = useState([new Date(), new Date()]);
  // const [roomDateStart, roomDateEnd] = roomDateRange;
  // const [adult, setAdult] = useState('')
  const [name, setDestination] = useState('');
  // const [data, setData] = useState({
  //   checkIn: roomDateStart,
  //   checkOut: roomDateEnd,
  //   Adults: '',
  //   children: ''
  // });

  // useEffect(() => {
  //   const [adultValue, childrenValue] = adult.split(',');
  //   setData({
  //     checkIn: roomDateStart,
  //     checkOut: roomDateEnd,
  //     Adults: adultValue,
  //     children: childrenValue
  //   });
  // }, [roomDateStart, roomDateEnd, adult]);

  const handleSearch = async () => {
    try {
      const mandirResponse = await api.mandir.search({name});
      const cityResponse = await api.mandir.searchcity({name});
      if (mandirResponse.data.data && mandirResponse.data.data.length > 0) {
        navigate('/mandirDetail?id=' + mandirResponse.data.data[0].id);

      } else if (cityResponse.data.data && cityResponse.data.data.length > 0) {
        console.log(cityResponse.data.data);
        navigate('/mandir?id=' + cityResponse.data.data[0].id);
      } else {
        console.warn('No data found in the response');
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'not found',
      })
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  // const hello = async () => {
  //   console.log({data});
  //   try {
  //     const response = await api.room.searchroom(data);
  //     if (response.data.data && response.data.data.length > 0) {
  //       console.log(response.data.data);
  //       localStorage.setItem("roomDateRange", roomDateRange)
  //       localStorage.setItem("adult", adult)
  //       navigate('/checkout')
  //       window.scrollTo(0, 0)
  //     } else {
  //       console.warn('No data found in the response');
  //       Swal.fire({
  //         icon: 'error',
  //         title: 'Oops...',
  //         text: 'Room not found',
  //     })
  //     }
  //   } catch (error) {
  //     console.error('Error fetching data:', error);
  //   }
  // }

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   handleSearch();
  // };

  useEffect(() => {
    if (token) {
      api.mandir.list().then(res => {
        if (res.data?.data) {
          dispatch(updateMandir(res.data.data))
        }
      })
      api.room.list().then(res => {
        if (res.data?.data) {
          dispatch(updateRoom(res.data.data))
        }
      })
    }
  }, [token, dispatch])

  const userLogout = () => {
    dispatch(updateToken(""))
    dispatch(getUser({}));
  }

  const handleCollapseClose = () => {
    const collapseElement = document.getElementById('collapseHeader');
    // Ensure the collapseElement is found
    if (collapseElement) {
      // Use Bootstrap's collapse function to close the collapse
      const bootstrapCollapse = new window.bootstrap.Collapse(collapseElement);
      bootstrapCollapse.hide();
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  return (
    <div className="header">
      <div className="top-header d-flex align-items-center" style={{ background: "#1A2B48", minHeight: "51px", color: "white" }}>
        <div className="container c-flex h-100">
          <div className="left d-flex align-items-center">
            <div className="social d-flex  border-right">
              <FaFacebookF />
              <FaLinkedinIn />
              <FaYoutube />
            </div>
            <div className="email">{user?.email ? user.email : 'info@atithibhavan.com'}</div>
          </div>
          <div className="right d-flex">
            {/* <div className="phone border-right">(000) 999 - 656 -888</div> */}
            {token ? <div
              style={{ cursor: "pointer" }}
              onClick={userLogout}
            >
              Logout
            </div> : <>
              <div
                className="border-right"
                data-bs-toggle="modal"
                data-bs-target="#loginModal"
                style={{ cursor: "pointer" }}
              >
                Login
              </div>
              <div
                data-bs-toggle="modal"
                data-bs-target="#signModal"
                style={{ cursor: "pointer" }}
              >
                Sign Up
              </div>
            </>}
          </div>
        </div>
      </div>
      <div className={"d-flex justify-content-center main-carousel " + (isImage ? '' : 'hide-carousel')} style={{ position: "relative", background: !isImage ? "#333333" : "" }}>
      
        <div id="carouselRoom" className="carousel slide home-carousel">
        <Carousel interval={3000} controls={false}>
        <Carousel.Item style={{ height: '100%' }}>
          <img src={isMobile ? c2 : c1} className="d-block w-100" alt="dham" />
        </Carousel.Item>
        <Carousel.Item style={{ height: '100%' }}>
          <img src={isMobile ? c2 : c1} className="d-block w-100" alt="Kutch" />
        </Carousel.Item>
        <Carousel.Item style={{ height: '100%' }}>
          <img src={isMobile ? c2 : c1} className="d-block w-100" alt="Sarangpur" />
        </Carousel.Item>
      </Carousel>
          {/* {isTab &&
            <>
              <button
                className="carousel-control-prev"
                type="button"
                data-bs-target="#carouselRoom"
                data-bs-slide="prev"
              >
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
              </button>
              <button
                className="carousel-control-next"
                type="button"
                data-bs-target="#carouselRoom"
                data-bs-slide="next"
              >
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
              </button>
            </>
          } */}

        </div>
        <div className={"main-header container " + (isImage ? 'trans' : '')}>
          <header className={"d-flex flex-wrap align-items-center justify-content-center justify-content-md-between py-3 "}>
            <div className="mb-2 mb-md-0">
              <Link to="/" className="d-inline-flex link-body-emphasis text-decoration-none">
                <img src={Header_Footer_Logo} alt="mixmap" className="logo" />
              </Link>
            </div>

            <ul className="nav middle-header mb-2 justify-content-center mb-md-0">
              <li><Link to="/" className="nav-link px-4">Home</Link></li>
              <li className="nav-link dropdown px-4">
                <span className="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                  Temple
                </span>
                <ul className="dropdown-menu dropdown-menu-dark bg1">
                  {mandir.map((e, i) => {
                    return <li onClick={() => navigate('/mandirDetail?id=' + e.id)}><Link className="dropdown-item">{e.mandirName + " , " + e.manCity}</Link></li>
                  })}
                </ul>
              </li>
              {/* <li className="nav-link dropdown px-4">
                <span className="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                  Room
                </span>
                <ul className="dropdown-menu dropdown-menu-dark bg1">
                  {room.map((e, i) => {
                    return <li onClick={() => navigate('/roomDetail?id=' + e.id)}><Link className="dropdown-item">{e.roomName}</Link></li>
                  })}
                </ul>
              </li> */}
              <li><Link to="/events" className="nav-link px-4">Events</Link></li>
              <li><Link to="/aboutUs" className="nav-link px-4">About Us</Link></li>
            </ul>

            <div className="end-header text-end" style={{ marginRight: '-60px' }}>
              <img src={basket} alt="basket" className="me-4" role="button" onClick={() => navigate("/checkout")} />
              <button type="button" onClick={() => navigate("/contactUS")} className="btn btn-primary me-4">Contact Us</button>
        <GoSearch size="34px" className="text-white" />
              <input
        type="text"
        placeholder="Enter search place"
        style={{
          border: 'none',
            borderBottom: '1px solid #ced4da',
            borderRadius: '0',
            outline: 'none',
            marginLeft: '5px',
            backgroundColor: 'transparent',
        }}
        onKeyDown={handleKeyPress}
        value={name}
        onChange={(e) => setDestination(e.target.value)}
      />
            </div>
          </header>
        </div>
        <div className="mobile">
          <div className={"main-header container " + (isImage ? 'trans' : '')}>
            <nav className="navbar navbar-light w-100">
              <Link to="/" className="d-inline-flex link-body-emphasis text-decoration-none">
                <img src={Header_Footer_Logo} alt="atithibhavan" className="logo" />
              </Link>
              <div className="navbar-brand m-2 ms-auto" type="button" data-bs-toggle="collapse" data-bs-target="#collapseHeader" aria-expanded="false" aria-controls="collapseHeader">
                <AiOutlineMenu />
              </div>
            </nav>
            
            <div className="collapse" id="collapseHeader">
              <ul className="nav middle-header pb-2 flex-column mb-2 justify-content-center mb-md-0">
                <li onClick={handleCollapseClose}><Link to="/" className="nav-link px-3">Home</Link></li>
                <li className="nav-link dropdown px-3">
                  <span className="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                    Temple
                  </span>
                  <ul className="dropdown-menu dropdown-menu-dark bg1" onClick={handleCollapseClose}>
                    {mandir.map((e, i) => {
                      return <li onClick={() => navigate('/mandirDetail?id=' + e.id)}><Link className="dropdown-item">{e.mandirName + " , " + e.manCity}</Link></li>
                    })}
                  </ul>
                </li>
                {/* <li className="nav-link dropdown px-3">
                  <span className="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                    Room
                  </span>
                  <ul className="dropdown-menu dropdown-menu-dark bg1" onClick={handleCollapseClose}>
                    {room.map((e, i) => {
                      return <li onClick={() => navigate('/roomDetail?id=' + e.id)}><Link className="dropdown-item">{e.roomName}</Link></li>
                    })}
                  </ul>
                </li> */}
                <li><Link to="/events" className="nav-link px-3">Events</Link></li>
                <li><Link to="/aboutUs" className="nav-link px-3" onClick={handleCollapseClose}>About Us</Link></li>
                {token ? <li><button type="button" className="btn nav-link me-1" onClick={userLogout}>Logout</button>
                </li> : <><li><button type="button" className="btn nav-link me-1" data-bs-toggle="modal"
                data-bs-target="#loginModal" onClick={handleCollapseClose} >Login</button></li></>}
                <li><button type="button" className="btn nav-link me-1" data-bs-toggle="modal"
                data-bs-target="#signModal" onClick={handleCollapseClose}>Sign Up</button></li>
                <li>
                  <div className="container">
                    <input className="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search" />
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className={"container text-white " + (isTab ? 'carousel-content' : 'carousel-content-tab')}>
        {window.innerWidth > 767 && (
        <>
          <h1 className="mb-0" style={{ fontFamily: "'Libre Baskerville', serif" }}>{isTab ? "Hi There!" : "Search Temple Popup Map"}  </h1>
          {isTab && <div>Where would you like to go?</div>}
          </>
        )}
        </div>
        {/* {isTab &&
          <div className="container px-5 headerCard position-absolute" style={{ bottom: "0" }}>
            <div className="room-container">
              <div className="card-section">
                <ul className="nav nav-tabs tabs card-tabs" id="myTab" role="tablist" style={{ borderBottom: "none" }}>
                  <li className="nav-item active ms-md-3" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">
                    <Link className="nav-link" style={{ border: "none" }} to="/" >Temple</Link>
                  </li>
                  <li className="nav-item" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">
                    <Link className="nav-link" style={{ border: "none" }}>Rooms</Link>
                  </li>
                </ul>
                <div className="tab-content" id="myTabContent">
                  <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                    <div className="card card-form tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                      <div className="row w-100 m-0">
                        <div className='col-md-10 px-4 px-md-4 py-2 py-md-0 d-flex flex-column flex-md-row align-items-center' style={{ borderRight: "1px solid #D7DCE3" }}>
                          <SlLocationPin />
                          <div className='ps-3 w-100'>
                            <div className="checkin text-secondary">Temple</div>
                            <form onSubmit={handleSubmit} >
                               <input
                                type="text"
                                placeholder="Enter Temple Name"
                                style={{
                                  border: 'none',
                                  width: '100%',
                                  borderBottom: '1px solid #ced4da',
                                  borderRadius: '0',
                                  outline: 'none'
                                }}
                                value={name}
                                onChange={(e) => setDestination(e.target.value)}
                               />
                               </form>
                          </div>
                          
                        </div>
                          <div className="col-md-2 px-md-4 py-2 p-md-3 px-5 text-center">
                          <button type="button" className="btn button1 btn-primary" onClick={handleSearch}
                          >Search</button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                    <div className="card card-form tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                      <div className="row w-100 m-0">
                        <div className='col-md-3 px-4 px-md-4 py-2 py-md-0 d-flex align-items-center' style={{ borderRight: "1px solid #D7DCE3" }}>
                          <SlLocationPin />
                          <div className='ps-3 w-100'>
                            <div className="checkin text-secondary">Destination</div>
                            <div className="field">Where are you going?</div>
                          </div>
                        </div>
                        <div className='col-md-4 px-4 px-md-3 py-2 py-md-0 d-flex align-items-center' style={{ borderRight: "1px solid #D7DCE3" }}>
                          <CiCalendarDate />
                          <div className='ps-3 w-100'>
                            <div className="checkin">Check In - Out</div>
                            <ReactDatePicker
                              selectsRange={true}
                              startDate={roomDateStart}
                              endDate={roomDateEnd}
                              onChange={(update) => {
                                setRoomDateRange(update);
                              }}
                              withPortal
                            />
                          </div>
                        </div>
                        <div className='col-md-3 px-4 px-md-3 py-2 py-md-0 d-flex align-items-center' style={{ borderRight: "1px solid #D7DCE3" }}>
                          <BsPeople />
                          <form className="form-floating w-100">
                            <select className="form-select field border-0 guestSelector" id='guestSelectorRoom' aria-label="Default select example" onChange={(e) => setAdult(e.target.value)}>
                            <option selected value="1,0">1 Adult - 0 Children</option>
                            <option value="2,0">2 Adult - 0 Children</option>
                            <option value="1.1">1 Adult - 1 Children</option>
                            <option value="1,2">1 Adult - 2 Children</option>
                            <option value="2,1">2 Adult - 1 Children</option>
                            <option value="2,2">2 Adult - 2 Children</option>
                            </select>
                            <label for="guestSelectorRoom"><p className='checkin text-secondary'>Guests</p></label>
                          </form>
                        </div>
                        <div className="col-md-2 px-md-4 py-2 p-md-3 px-5 text-center">
                          <button type="button" className="btn button1 btn-primary" onClick={hello}>Search</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        } */}
      </div>
      {/* Modals */}
      <Login />
      <SignUp />
      <ForgetPassword />
    </div>
  );
};

export default Header;
