import React from 'react'
import star from "../images/gujarat/fill-star.png";
import halfstar from "../images/gujarat/star.png";
import watch from "../images/gujarat/watch.png";
import charge from "../images/gujarat/charge.png";
import offer from "../images/gujarat/10%.png";
import { useNavigate } from 'react-router-dom';
import { BASE_URL } from "../api";

const TrendingHotelCard = ({ element }) => {
    const navigate = useNavigate()

    // const isHalfstar = stars * 10 % 10 > 0;
    return (
        <div className="card" onClick={() => navigate('/roomDetail?id=' + element.id)}>
            <img src={BASE_URL + "images/" + element.image} className="card-img-top" alt={element.roomName} />
            {/* {originalPrice && <img src={offer} alt="..." className="position-absolute" style={{ right: "0%", maxWidth: "55px", borderTopRightRadius: "5px" }} />} */}
            <img src={offer} alt="..." className="position-absolute" style={{ right: "0%", maxWidth: "55px", borderTopRightRadius: "5px" }} />
            <div className="card-body">
                <h6 className="card-title fw-bold fw-bold">{element.roomName}</h6>
                {/* <div className="d-flex">
                    <img
                        src={map}
                        alt="map"
                        style={{ maxHeight: "17px", maxWidth: "17px" }}
                    />
                    <span className="normal-font ps-2 text-secondary">
                        Los Angeles, CA, USA
                    </span>
                </div> */}
                <div className="d-flex justify-content-between pt-4 align-items-end">
                    {element.like ?
                        <div className='d-flex align-items-end'>
                            <div>
                                {Array(Math.floor(5)).fill(null).map((v, i) => <img
                                    key={i}
                                    src={star}
                                    alt="star"
                                    style={{ maxHeight: "15px", maxWidth: "17px" }}
                                />
                                )}
                                {/* {isHalfstar && */}
                                <img
                                    src={halfstar}
                                    alt="star"
                                    style={{ maxHeight: "15px", maxWidth: "17px" }}
                                />
                                {/* } */}
                            </div>
                            <ul className="pt-1 ps-4 mb-0">
                                <li style={{ color: "#6A7A84" }} className="normal-font">
                                    {/* {reviews} Reviews */}
                                    1 Reviews
                                </li>
                            </ul>
                        </div> :
                        <div className="normal-font" style={{ color: "#6A7A84" }}>
                            No Review
                        </div>
                    }
                    {/* {originalPrice && <div className="normal-font" style={{ color: "#CC0033" }}><del>{originalPrice}</del></div>} */}
                </div>
                <div className="row pt-2">
                    <div className="col d-flex align-items-center">
                        <div className="d-flex">
                            <img
                                src={watch}
                                alt="watch"
                                style={{ maxHeight: "16px", maxWidth: "16px" }}
                            />
                            <span className="normal-font ps-1"> 1 days</span>
                        </div>
                    </div>
                    <div className="col">
                        <div className="d-flex align-items-center">
                            <img
                                src={charge}
                                alt="charge"
                                style={{ maxHeight: "16px", maxWidth: "11px" }}
                            />
                            <span className="normal-font text-secondary px-1">
                                from
                            </span>
                            <span className="text-500">₹{element.price}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default TrendingHotelCard