export const aboutUs = [
    {
        name: "Home",
        link: "/"
    },
    {
        name: "About Us",
        link: "/aboutUs"
    }
]
export const contactUs = [
    {
        name: "Home",
        link: "/"
    },
    {
        name: "Contact Us",
        link: "/contactUs"
    }
]
export const afterCheckout = [
    {
        name: "Home",
        link: "/"
    },
    {
        name: "Booking Success",
        link: "/afterCheckout"
    }
]
export const checkout = [
    {
        name: "Home",
        link: "/"
    },
    {
        name: "Checkout",
        link: "/checkout"
    }
]

export const mandirDetail = [
    {
        name: "Home",
        link: "/"
    },
    {
        name: "City",
        link: "/mandir"
    },
    {
        name: "Mandir",
        link: "/mandirDetail"
    }
]
export const roomDetail = [
    {
        name: "Home",
        link: "/"
    },
    {
        name: "City",
        link: "/mandir"
    },
    {
        name: "Mandir",
        link: "/mandirDetail"
    },
    {
        name: "Room",
        link: "/roomDetail"
    }
]

const path = {
    aboutUs,
    contactUs,
    afterCheckout,
    checkout,
    mandirDetail,
    roomDetail,
}

export default path