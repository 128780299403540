import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    checkout: [],
}

export const checkoutSlice = createSlice({
    name: 'checkout',
    initialState,
    reducers: {
        updateCheckout: (state, action) => {
            state.checkout = action.payload;
        }
    },
})

// Action creators are generated for each case reducer function
export const { updateCheckout } = checkoutSlice.actions

export default checkoutSlice.reducer