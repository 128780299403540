import React, {useState} from 'react'
import './Footer.css'
import subMailIcon from '../images/icons/ico_email_subscribe.svg'
import cardsImages from '../images/icons/cards.png'
import { FaFacebook, FaTwitter, FaYoutube } from 'react-icons/fa';
import Header_Footer_Logo from '../images/icons/Header_Footer_Logo.svg'
import { Link } from "react-router-dom";
import Swal from 'sweetalert2';
import api from '../api';

const Footer = () => {
    const [form, setForm] = useState({
        email: '',
    });

    const changeValue = (e) => {
        const { name, value } = e.target;
    setForm((prevForm) => ({ ...prevForm, [name]: value }));
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        api.message.create(form).then((res) => {
            if (res.data?.data) {
                Swal.fire(
                    'success'
                )
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: res.data.msg,
                })
            }
        }).catch(error => {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: error.response.data.message
            })
        })
    }
    const redirectToRefund = () => {
        window.location.replace('/Return&RefundPolicy')
      };
    const redirectToTerms = () => {
        window.location.replace('/terms&Conditions')
      };
    const redirectToAboutus = () => {
        window.location.replace('/aboutUs')
      };
    const redirectToContactus = () => {
        window.location.replace('/contactUs')
      };
    const redirectToPolicy = () => {
        window.location.replace('/PrivacyPolicy')
      };
    return (
        <div className='footer'>
            <div className="update d-flex align-items-center">
                <div className='container px-md-5 px-4'>
                    <div className="row px-md-5 mx-lg-5 mx-md-2 px-3">
                        <div className="p-1 py-2 col-md-6 subscription d-flex ">
                            <img src={subMailIcon} width='54px' className='me-3' alt="subMailIcon" />
                            <div className="detail ps-3">
                                <div className="title">Get Updates & More</div>
                                <div className="sub-title pt-1">Thoughtful thoughts to your inbox</div>
                            </div>
                        </div>
                        <div className="p-1 py-2 col-md-6 subscription-form">
                            <div className="input-group input-group-lg">
                                <input value={form.email} onChange={changeValue} type="text" className="form-control" placeholder='Your Email' aria-label="email input" style={{fontSize:"15px"}} aria-describedby="inputGroup-sizing-lg" name="email" />
                                <button className="input-group-text primary-bg-color text-white" onClick={handleSubmit} id="inputGroup-sizing-lg" style={{fontSize:"14px"}}>SUBCRIBE</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="details py-3 d-flex align-items-center">
                <div className='container'>
                    <div className="row">
                        <div className="p-2 px-5 col-lg-4 footer-column">
                            <div className="head">NEED HELP?</div>
                            <hr className='mb-5'/>
                            {/* <div className="info">
                                <div className="label">Call Us</div>
                                <div className="item pt-2">+ 00 222 44 5678</div>
                            </div> */}
                            <div className="info">
                                <div className="label">Email for Us</div>
                                <div className="item pt-2">info@atithibhavan.com</div>
                            </div>
                            <div className="info">
                                <div className="label">Follow Us</div>
                                <div className="item pt-2">
                                    <FaFacebook />
                                    <FaTwitter />
                                    <FaYoutube />
                                </div>
                            </div>
                        </div>
                        <div className="p-2 px-5 col-lg-2 footer-column">
                            <div className="head">Links</div>
                            <hr className='mb-5'/>
                            <div className="links cursor-pointer"><Link to="/aboutUs" className="custom" onClick={redirectToAboutus}>About Us</Link></div>
                            <div className="links cursor-pointer"><Link to="/contactUs" className="custom" onClick={redirectToContactus}>Contact Us</Link></div>
                            {/* <div className="links cursor-pointer">Rewards</div> */}
                            <div className="links cursor-pointer">Mandir</div>
                            <div className="links cursor-pointer">Support</div>
                        </div>
                        <div className="p-2 px-5 col-lg-2 footer-column">
                            <div className="head">Useful Links</div>
                            <hr className='mb-5'/>
                            <div className="links cursor-pointer">Account</div>
                            {/* <div className="links cursor-pointer">Legal</div> */}
                            {/* <div className="links cursor-pointer">Contact</div> */}
                            <div className="links cursor-pointer"><Link to="/terms&Conditions" className="custom" onClick={redirectToTerms}>Terms & Conditions</Link></div>
                            <div className="links cursor-pointer"><Link to="/PrivacyPolicy" className="custom" onClick={redirectToPolicy}>Privacy Policy</Link></div>
                            <div className="links cursor-pointer"><Link to="/Return&RefundPolicy" className="custom" onClick={redirectToRefund}>Cancellation Policy</Link></div>
                        </div>
                        <div className="p-2 px-5 col-lg-4 footer-column">

                            <img src={Header_Footer_Logo} alt="atithibhavan" className="logo1" />
                            {/* <div className="head">SETTINGS</div>
                            <br />
                            <label htmlFor="curr" className="form-label curr-label text-secondary">Currencies</label>
                            <div className="input-group">
                                <select className="form-select" id="curr">
                                    <option value="USD" selected>USD</option>
                                    <option value="INR">INR</option>
                                    <option value="EUR">EUR</option>
                                </select>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
            <hr />
            <div className="copy d-flex align-items-center">
                <div className="container">
                    <div className="row">
                        {/* <div className="p-1 ps-md-5 col-md-6 copy-right">Copyright © 2022 by <Link to='/'>Traveler Theme</Link></div> */}
                        <div className="p-1 ps-md-5 col-md-6 copy-right">
                            Developed By <Link to='https://codzcartinfotech.com' style={{ textDecoration: 'none', color: 'inherit' }}>Codzcart Infotech</Link>
                            </div>

                        <div className="p-1 pe-md-5 col-md-6 cards"><img src={cardsImages} alt="cardImages" /></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer