import React, { useState } from 'react'
import { user } from '../api';
import { useDispatch } from 'react-redux';
import { getUser, updateToken } from '../store/user';
import Swal from 'sweetalert2';


const ForgetPassword = () => {
    const dispatch = useDispatch();
    const [email, setEmail] = useState("");
    const onChange = (e) => {
        const value = e.target.value;
        const key = e.target.name;
        if (key === "email") {
            setEmail(value)
        }
    }
    const onSubmit = (e) => {
        e.preventDefault();
        user.forgotpassword({ email }).then(res => {
            if (res.data.data) {
                dispatch(updateToken(res.data.data));
                dispatch(getUser(res.data.userData));
                setEmail("");
                document.getElementById("closeForgetModal").click()
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: res.data.message,
                })
            }
        }).catch(error => {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: error.response.data.message
            })
        })
    }
    return (
        <div
            className="modal fade"
            id="forgetModal"
            tabindex="-1"
            aria-labelledby="forgetModalLabel"
            aria-hidden="true"
        >
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header border-0 px-4">
                        <h6 className="text-secondary mb-0">Forget Password</h6 >
                        <button
                            id="closeForgetModal"
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        ></button>
                    </div>
                    <div className="modal-body px-4">
                        <form>
                            <input className="form-control form-control-lg mb-4" value={email} onChange={onChange} name='email' type="email" style={{ borderRadius: "3px" }} placeholder="Email" />
                            <div className="text-center">
                                <button type="button" className="btn btn-primary w-100 p-2" onClick={onSubmit} style={{ background: "#5191FA", borderRadius: "3px" }} data-bs-dismiss="modal">Forget Password</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ForgetPassword