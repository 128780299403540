import React, { useState } from "react";
import { user } from "../api";
import { useDispatch } from "react-redux";
import { getUser, updateToken } from "../store/user";
import Swal from "sweetalert2";
import { useLocation, useNavigate } from "react-router-dom";

const ResetPassword = () => {
	const dispatch = useDispatch();
	const location = useLocation();
	const navigate = useNavigate();
	const searchParams = new URLSearchParams(location.search);
	const token = searchParams.get("token");
	console.log({ token });
	const [Token, setToken] = useState(token);
	const [newPassword, setPassword] = useState("");
	const onChange = (e) => {
		setPassword(e.target.value);
	};
	const onSubmit = (e) => {
		e.preventDefault();
		user.resetpassword({ token: Token, newPassword })
			.then((res) => {
				if (res.data.data) {
					dispatch(updateToken(res.data.data));
					dispatch(getUser(res.data.userData));
					setToken(token);
					setPassword("");
					document.getElementById("closeLoginModal").click();
					navigate('/');
				} else {
					Swal.fire({
						icon: "error",
						title: "Oops...",
						text: res.data.message,
					});
				}
			})
			.catch((error) => {
				Swal.fire({
					icon: "error",
					title: "Oops...",
					text: error.response.data.message,
				});
			});
	};
	return (
		<div className="modal-body px-4" style={{ paddingTop: '80px', width: '50%', margin: 'auto' }}>
			<form>
				<input
					value={newPassword}
					name="newPassword"
					onChange={onChange}
					type="password"
					className="form-control form-control-lg mb-4"
					placeholder="Enter new password"
					required
				/>
				<div className="text-center">
					<button
						type="button"
						className="btn btn-primary w-100 p-2"
						onClick={onSubmit}
						style={{ background: "#5191FA", borderRadius: "3px" }}
					>
						Submit
					</button>
				</div>
			</form>
		</div>
	);
};

export default ResetPassword;
