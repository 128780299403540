import React from 'react';
// import temple from "../images/temple/temple.png";
import map from "../images/gujarat/map.png";
import heart from "../images/gujarat/heart.png";
// import orangestar from "../images/gujarat/orangestar.png";
// import criclerimg from "../images/temple/roundimg.png"
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../api";
const Temple = (props) => {
    const navigate = useNavigate()
   
    return (
        <>
            <div className={props.logo ? "col-md-6 col-lg-4 py-2 col-xl-3" : "col-md-6 col-xl-4 py-2 col-xl-3"} onClick={()=>navigate('/mandirDetail?id=' + props.id)}>
                <div className="card h-100">
                    <div className="position-relative">
                        <img src={BASE_URL + "images/" + props.image} className="card-img-top" alt="temple" width="100%" height="270px" style={{borderRadius:"8px"}}/>
                        <div className="position-absolute" style={{top:"7%",right:"4%",transform:"translate(-4%,-15%)"}}>
                            <img src={heart} alt="heart" style={{maxWidth:"28px"}}/>
                        </div>
                        {/* <div className="position-absolute" style={{bottom:"10%",left:"10%",transform:"translate(-10%,-10%)"}}>
                            <img src={orangestar} alt="orangestar" className="m-1"  style={{maxWidth:"23px"}}/>
                            <img src={orangestar} alt="orangestar" className="m-1" style={{maxWidth:"23px"}}/>
                            <img src={orangestar} alt="orangestar" className="m-1" style={{maxWidth:"23px"}}/>
                            <img src={orangestar} alt="orangestar" className="m-1" style={{maxWidth:"23px"}}/>
                            <img src={orangestar} alt="orangestar" className="m-1" style={{maxWidth:"23px"}}/>
                        </div>
                        {props.logo && 
                            <div className="position-absolute" style={{bottom:"-10%",right:"8%",transform:"translate(-1%,-2%)"}}>
                                <img src={criclerimg} alt='cricler' className="rounded-circle" style={{maxWidth:"45px"}}/>
                            </div>
                        } */}
                    </div>
                    <div className="card-body pt-4">
                        <h6 className="card-title fw-bold text-black" >{props.mandirName}</h6>
                        <div className="d-flex">
                            <img
                                src={map}
                                alt="map"
                                style={{ maxHeight: "17px", maxWidth: "17px" }}
                            />
                            <span className="normal-font ps-1 text-secondary">
                                {props.manCity}, { props.manState}, {props.manCountry}
                            </span>
                        </div>
                        <div className="d-flex pt-4 align-items-end">
                            <div className="text-primary">{props.reviews}/5 Very Good</div>
                            <ul className="pt-1 ps-4 mb-0">
                                <li style={{ color: "#6A7A84" }} className="normal-font">
                                    {props.reviews} Reviews
                                </li>
                            </ul>
                        </div>
                        {/* <div className="row pt-2">
                            <div className="col">
                                <div className="d-flex align-items-center">
                                    <img
                                        src={charge}
                                        alt="charge"
                                        style={{ maxHeight: "16px", maxWidth: "11px" }}
                                    />
                                    <span className="normal-font text-secondary px-1">
                                        from
                                    </span>
                                    <span className="text-500" style={{color:"#1A2B48"}}>₹{props.amount}<span className="text-secondary">/night</span></span>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Temple;