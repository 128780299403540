import React from 'react';
// import roomImg from '../images/roomDetail/room1.png';
import { AiOutlineHeart } from 'react-icons/ai';
import { FaShare } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import childIcon from '../images/roomDetail/child.svg'
import peopleIcon from '../images/roomDetail/people.svg'
import bedIcon from '../images/roomDetail/bed.svg'
import roomSizeIcon from '../images/roomDetail/roomSize.svg'
import airConditioningIcon from '../images/roomDetail/airConditioning.svg'
import tvIcon from '../images/roomDetail/tv.svg'
import hotwaterIcon from '../images/roomDetail/hotwater.svg'
import kitchenIcon from '../images/roomDetail/kitchen.svg'
import shampooIcon from '../images/roomDetail/shampoo.svg'
import Table from '../components/Table';
import RoomData from '../components/RoomData';
import RoomBook from '../components/RoomBook';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import api, { BASE_URL } from '../api';
import { getOneRoom } from '../store/room';
import { usePageId } from '../hooks';

const RoomDetail = () => {
  const id = usePageId();

  const dispatch = useDispatch();
  const token = useSelector((state) => state.user.token);
  const roomDetail = useSelector((state) => state.room.roomdetails);

  useEffect(() => {
    api.room.getone(id).then(res => {
      if (res.data?.data) {
        dispatch(getOneRoom(res.data.data))
        window.scrollTo(0, 0)
      }
    })
  }, [token, dispatch, id]);

  const details = [
    {
      img: roomSizeIcon,
      alt: "roomSizeIcon",
      value: `S: ${roomDetail.size}`,
      sup: "2"
    },
    {
      img: bedIcon,
      alt: "bedIcon",
      value: `Beds: ${roomDetail.beds}`
    },
    {
      img: peopleIcon,
      alt: "peopleIcon",
      value: `Adult: ${roomDetail.Adults}`,
    },
    {
      img: childIcon,
      alt: "childIcon",
      value: `Children: ${roomDetail.children}`,
    }
  ]

  // const Description = [
  //   "Room Sheraton comprises of 1 Double Bed or 2 Twin Beds, 2 Bedside Tables, a Desk & Chair. The room is furnished with wall to wall carpeting, trendy furnishings and a balcony.",
  //   "Our ultramodern glass bathroom is equipped with hairdryer, magnifying shaving and make up mirror as well as all the amenities you could possible need during your stay.",
  //   "A Complimentary Bottle of Wine, Fresh Fruit and Mineral Water, are provided on arrival. Electric current: 220 Volts. Smoking rooms & inter-connecting rooms are also available."
  // ]

  return (
    <div className='room-detail'>
      <div id="carouselRoom" className="carousel slide">
        <div className="carousel-inner">
          <div className="carousel-item active">
            <img src={BASE_URL + "images/" + roomDetail.image} className="d-block w-100" alt="roomImage" />
          </div>
        </div>
        <div className="carousel-control-prev br-3" type="button" data-bs-target="#carouselRoom" data-bs-slide="prev">
        </div>
        <div className="carousel-control-next br-3" type="button" data-bs-target="#carouselRoom" data-bs-slide="next">
        </div>
        <div className="top-up">
          <div role='button' className="share circular-icon"><FaShare /></div>
          <div role='button' className="like circular-icon"><AiOutlineHeart /></div>
        </div>
      </div>
      <div className='room-container container pt-5 px-3'>
        <div className="row">
          <div className="col-md-9 pb-5">
            <div className="head">
              <h2 className='title'>Room Sheraton Hotel</h2>
              <div className='sub-title'>Hotel: <Link to="/mandir?id=1" className='text-decoration-none'>{roomDetail.roomName}</Link></div>
            </div>
            <hr style={{ color: "#94a0a9" }} className='mt-5 mb-4' />
            <div className="config row">
              {details.map((element, index) => {
                return <RoomData {...element} />
              })}
            </div>
            <hr style={{ color: "#94a0a9" }} />
            <div className="main-description pt-3 pb-5">
              <h3 className='title'>Description</h3>
              {/* {Description.map((v,i)=>{ */}
              <p>{roomDetail.desc}</p>
              {/* })} */}
            </div>
            <hr style={{ color: "#94a0a9" }} />
            <div className="table-section pt-3">
              <h3 className="title">Bulk discount (by Percent)</h3>
              <Table title="Bulk discount adult" data={roomDetail} />
            </div>
            <div className='facilities'>
              <h3 className='title'>Room facilities</h3>
              <div className="py-2 row">
                <div className="col-md-4 option d-flex align-items-center">
                  <img src={airConditioningIcon} alt="Air Conditioning" />
                  <div className="desc">Air Conditioning</div>
                </div>
                <div className="col-md-4 option d-flex align-items-center">
                  <img src={tvIcon} alt="Flat Screen TV" />
                  <div className="desc">Flat Screen TV</div>
                </div>
                <div className="col-md-4 option d-flex align-items-center">
                  <img src={hotwaterIcon} alt="Hot water" />
                  <div className="desc">Hot water</div>
                </div>
                <div className="col-md-4 option d-flex align-items-center">
                  <img src={kitchenIcon} alt="Kitchen" />
                  <div className="desc">Kitchen</div>
                </div>
                <div className="col-md-4 option d-flex align-items-center">
                  <img src={shampooIcon} alt="Shampoo" />
                  <div className="desc">Shampoo</div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3 pb-5">
            <RoomBook roomId={id} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default RoomDetail