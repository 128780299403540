import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    mandirDetails: {}
}

export const mandirDetailsSlice = createSlice({
    name: 'mandirDetails',
    initialState,
    reducers: {
        updateMandirDetails: (state, action) => {
            state.mandirDetails = action.payload;
        },
    },
})

// Action creators are generated for each case reducer function
export const { updateMandirDetails } = mandirDetailsSlice.actions

export default mandirDetailsSlice.reducer