import React, {useState} from "react";
import GoogleMapReact from 'google-map-react';
import help from "../images/gujarat/24help.png"
import Swal from 'sweetalert2';
import api from '../api';

const AnyReactComponent = ({ text }) => <div>{text}</div>;

const ContactUs = () => {
    const [form, setForm] = useState({
        name: '',
        email: '',
        message: '',
    });

    const changeValue = (e) => {
        const { name, value } = e.target;
    setForm((prevForm) => ({ ...prevForm, [name]: value }));
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        api.message.create(form).then((res) => {
            if (res.data?.data) {
                Swal.fire(
                    'success'
                )
                window.location.reload();
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: res.data.msg,
                })
            }
        }).catch(error => {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: error.response.data.message
            })
        })
    }
    const defaultProps = {
        center: {
            lat: 10.99835602,
            lng: 77.01502627
        },
        zoom: 11
    };
    return (
        <div className="container mb-5">
            <div className="row pt-3 pt-lg-5 px-3 pb-4">
                <div className="col-lg-5 col-md-6 pe-3 mb-2">
                    <div className="large-font fw-bold" style={{ color: "#1A2B48", fontSize: "39px" }}>Stay Connected with ease: Contact us today!</div>
                    <div className="small-font pt-3 text-secondary">At [Company Name], we value your feedback and strive to continuously improve our products and services to better meet your needs. So please don't hesitate to contact us with any questions, comments, or concerns - we're always here to listen and help in any way we can.</div>
                    <div className="small-font pt-2 text-secondary" >We love hearing from our visitors and customers! If you have any questions, comments, or feedback, please don't hesitate to get in touch with us. Our team is dedicated to providing you with the best possible service and support, and we're always here to help. Whether you're looking for help with a product, need more information about our company, or just want to say hello, we would love to hear from you. Feel free to use any of the contact methods below to get in touch with us - we look forward to hearing from you!</div>

                </div>
                <div className="col-lg-7 col-md-6 ps-lg-5">
                    <div className="large-font fw-bold" style={{ color: "#1A2B48", fontSize: "39px" }}>Let's get in touch</div>
                    <form className="pt-5">
                        <div className="form-group mb-4">
                            <input  value={form.name} type="text" className="form-control py-3 form-control-lg text-secondary small-font"  onChange={changeValue} style={{ background: "#EEEEEE" }} id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter your name" name="name" />
                        </div>
                        <div className="form-group mb-4">
                            <input value={form.email} type="email" className="form-control py-3 form-control-lg text-secondary small-font" onChange={changeValue} style={{ background: "#EEEEEE" }} id="exampleInputPassword1" placeholder="Enter your email" name="email" />
                        </div>
                        <div className="form-group mb-4">
                            <textarea value={form.message} className="form-control form-control-lg text-secondary small-font"
                            onChange={changeValue}  style={{ background: "#EEEEEE" }} id="exampleFormControlTextarea1" rows="6" placeholder="Enter your message" name="message"></textarea>
                        </div>
                    </form>
                    <div className="row">
                        <div className="col-lg-6 mb-3">
                            <button type="button" className="btn btn-primary small-font" onClick={handleSubmit} style={{ background: "#5191FA", borderRadius: "12px", border: "none", padding: "11px 25px" }}>Send Message</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row pt-3 pt-lg-5 px-3 mb-lg-3">
                <div className="col-lg-4 pe-xl-5 col-md-5">
                    <img src={help} alt="aboutus" className="pb-5" width="100%" />
                    <div>
                        <div className="title">Mail Address</div>
                        <div className="text-secondary pt-2">info@atithibhavan.com</div>
                        {/* <div className="title pt-4 mt-2">Phone Number</div>
                        <div className="text-secondary pt-2">(+000 - 000 - 0000) (+000 - 000 - 0000)</div> */}
                        <div className="title pt-4 mt-2">Address line</div>
                        <div className="text-secondary pt-2">401/8 rise on plaza Sarthana jakatnaka Varachha Surat</div>
                    </div>
                </div>
                <div className="col-lg-8 ps-lg-5 col-md-7">
                    <GoogleMapReact
                        bootstrapURLKeys={{ key: "" }}
                        defaultCenter={defaultProps.center}
                        defaultZoom={defaultProps.zoom}
                    >
                        <AnyReactComponent
                            lat={59.955413}
                            lng={30.337844}
                            text="My Marker"
                        />
                    </GoogleMapReact>
                </div>
            </div>
        </div>
    )
}

export default ContactUs;