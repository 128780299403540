import React from 'react'
import { FaFacebookF, FaGoogle } from 'react-icons/fa'
import { user } from '../api';
import { useDispatch } from 'react-redux';
import { getUser, updateToken } from '../store/user';
import Swal from 'sweetalert2';
import { useState } from 'react';
import { ROLE } from '../constants'
import { Link } from "react-router-dom";

const SignUp = () => {
    const dispatch = useDispatch();
    const [userName, setUserName] = useState("");
    const [fullName, setFullName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [role, setRole] = useState("user");
    const [terms, setTerms] = useState(false);
    const onChange = (e, etc) => {
        const value = e.target.value;
        const key = e.target.name;
        if (key === "userName") {
            setUserName(value)
        } else if (key === "fullName") {
            setFullName(value)
        } else if (key === "email") {
            setEmail(value)
        } else if (key === "password") {
            setPassword(value)
        } else if (key === "role") {
            setRole(etc)
        } else if (key === "terms") {
            setTerms(!terms)
        }
    }
    const onSubmit = (e) => {
        e.preventDefault();
        user.register({ userName, fullName, email, password, role }).then(res => {
            if (res.data.data) {
                user.login({ email, password }).then(res => {
                    if (res.data.data) {
                        dispatch(updateToken(res.data.data));
                        dispatch(getUser(res.data.userData));
                        setUserName("");
                        setFullName("");
                        setEmail("");
                        setPassword("");
                        setRole("user");
                        setTerms(false)
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: "Something went wrong when Loging you in, Please Try again",
                        })
                    }
                })
                document.getElementById("closeSignModal").click()
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: res.data.msg,
                })
            }
        })
    }
    const redirectToTerms = () => {
        window.location.replace('/terms&Conditions')
      };
    return (
        <div
            className="modal fade"
            id="signModal"
            tabindex="-1"
            aria-labelledby="signModalLabel"
            aria-hidden="true"
        >
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header  border-0 px-4">
                        <h6 className="text-secondary mb-0">Sign Up</h6 >
                        <button
                            id="closeSignModal"
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        ></button>
                    </div>
                    <div className="modal-body px-4">
                        <form>
                            <input className="form-control form-control-lg mb-4" name='userName' value={userName} onChange={onChange} style={{ borderRadius: "3px" }} type="text" placeholder="Username*" />
                            <input className="form-control form-control-lg mb-4" name='fullName' value={fullName} onChange={onChange} style={{ borderRadius: "3px" }} type="text" placeholder="Full Name" />
                            <input className="form-control form-control-lg mb-4" name='email' value={email} onChange={onChange} style={{ borderRadius: "3px" }} type="email" placeholder="Email*" />
                            <input className="form-control form-control-lg mb-4" name='password' value={password} onChange={onChange} type="password" style={{ borderRadius: "3px" }} placeholder="Password*" />
                            {/* <div className="row px-2 pb-3">
                                <div className="text-secondary px-0 pb-3" style={{ fontWeight: "600", fontSize: "18px" }}>Select User Type</div>
                                <div className="form-check">
                                    <input className="form-check-input" type="radio" name="role" onChange={(e) => onChange(e, ROLE.USER)} value={ROLE.USER} checked={role === ROLE.USER} id="normal" />
                                    <label className="form-check-label text-secondary " for="normal">
                                        Normal User
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="radio" name="role" onChange={(e) => onChange(e, ROLE.ADMIN)} value={ROLE.ADMIN} checked={role === ROLE.ADMIN} id="partner" />
                                    <label className="form-check-label text-secondary" for="partner">
                                        Partner User
                                    </label>
                                </div>
                            </div> */}
                            <div className="row mb-3">
                                <div className="col-md-12">
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value={terms} name='terms' onChange={onChange} id="terms" style={{ borderRadius: "2px", border: "1px solid #5E6D77" }} />
                                        <label className="form-check-label text-secondary" style={{ fontWeight: "600", fontSize: "14px" }} for="terms">
                                            I have read and accept the <span className="text-primary"><Link to="/terms&Conditions" onClick={redirectToTerms}>terms & conditions</Link></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="text-center">
                                <button type="button" className={"btn btn-primary w-100 p-2" + (!terms ? " disabled" : "")} onClick={onSubmit} style={{ background: "#5191FA", borderRadius: "3px" }}>SIGN UP</button>
                            </div>
                            <div className="row py-3 justify-content-center text-secondary">or continue with</div>
                            <div className="row">
                                <div className="col-md-6">
                                    <button type="button" className="btn btn-primary w-100 p-2 d-flex align-items-center justify-content-center" style={{ borderRadius: "3px" }}><FaFacebookF className="me-2" />Facebook</button>
                                </div>
                                <div className="col-md-6">
                                    <button type="button" className="btn btn-danger w-100 p-2 d-flex align-items-center justify-content-center" style={{ borderRadius: "3px" }}><FaGoogle className="me-2" />Google</button>
                                </div>
                            </div>
                            <div className="row py-4">
                                <div className="text-secondary text-center" style={{ fontWeight: "600" }}>Already have an account?
                                    <span className="text-primary"
                                        data-bs-toggle="modal"
                                        data-bs-target="#loginModal"
                                        style={{ cursor: "pointer" }}
                                    >
                                        Log In
                                    </span>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SignUp