import React, { useState } from 'react'
import { FaFacebookF, FaGoogle } from 'react-icons/fa'
import { user } from '../api';
import { useDispatch } from 'react-redux';
import { getUser, updateToken } from '../store/user';
import Swal from 'sweetalert2';

const Login = () => {
    const dispatch = useDispatch();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const onChange = (e) => {
        const value = e.target.value;
        const key = e.target.name;
        if (key === "email") {
            setEmail(value)
        } else if (key === "password") {
            setPassword(value)
        }
    }
    const onSubmit = (e) => {
        e.preventDefault();
        user.login({ email, password }).then(res => {
            if (res.data.data) {
                dispatch(updateToken(res.data.data));
                dispatch(getUser(res.data.userData));
                setEmail("");
                setPassword("");
                document.getElementById("closeLoginModal").click()
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: res.data.message,
                })
            }
        }).catch(error => {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: error.response.data.message
            })
        })
    }
    return (
        <div
            className="modal fade"
            id="loginModal"
            tabindex="-1"
            aria-labelledby="loginModalLabel"
            aria-hidden="true"
        >
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header border-0 px-4">
                        <h6 className="text-secondary mb-0">Login In</h6 >
                        <button
                            id="closeLoginModal"
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        ></button>
                    </div>
                    <div className="modal-body px-4">
                        <form>
                            <input className="form-control form-control-lg mb-4" value={email} onChange={onChange} name='email' type="email" style={{ borderRadius: "3px" }} placeholder="Email" required/>
                            <input className="form-control form-control-lg mb-4" value={password} onChange={onChange} name='password' type="password" style={{ borderRadius: "3px" }} placeholder="Password" />
                            <div className="text-center">
                                <button type="button" className="btn btn-primary w-100 p-2" onClick={onSubmit} style={{ background: "#5191FA", borderRadius: "3px" }}>LOG IN</button>
                            </div>
                            <div className="row pt-4">
                                <div className="col-md-6">
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" id="flexremember" style={{ borderRadius: "2px", border: "1px solid #5E6D77" }} />
                                        <label className="form-check-label text-secondary" for="flexremember">
                                            Remember me
                                        </label>
                                    </div>
                                </div>
                                <span className="col-md-6 text-end text-primary"
                                        data-bs-toggle="modal"
                                        data-bs-target="#forgetModal"
                                        style={{ cursor: "pointer" }} >
                                        Forgot Password?
                                    </span>
                                {/* <div className="col-md-6 text-end text-primary">Forgot Password?</div> */}
                            </div>
                            <div className="row justify-content-center text-secondary">or continue with</div>
                            <div className="row pt-3">
                                <div className="col-md-6">
                                    <button type="button" className="btn btn-primary w-100 p-2 d-flex align-items-center justify-content-center" style={{ borderRadius: "3px" }}><FaFacebookF className="me-2" />Facebook</button>
                                </div>
                                <div className="col-md-6">
                                    <button type="button" className="btn btn-danger w-100 p-2 d-flex align-items-center justify-content-center" style={{ borderRadius: "3px" }}><FaGoogle className="me-2" />Google</button>
                                </div>
                            </div>
                            <div className="row py-4">
                                <div className="text-secondary text-center" style={{ fontWeight: "600" }}>Do not have an account?
                                    <span className="text-primary"
                                        data-bs-toggle="modal"
                                        data-bs-target="#signModal"
                                        style={{ cursor: "pointer" }} >
                                        Sign Up
                                    </span>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login