import React, { useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { useNavigate } from "react-router-dom";
import HorizontalRoomDetails from './HorizontalRoomDetails';
// import { useDispatch, useSelector } from 'react-redux';
import api from '../api';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';

const MandirRoomSearch = () => {
    const search = useLocation().search;
    const navigate = useNavigate();
    // const token = useSelector((state) => state.user.token);
    const [templeDateRange, setTempleDateRange] = useState([new Date(), new Date()]);
    const [templeDateStart, templeDateEnd] = templeDateRange;
    const [roomDateRange, setRoomDateRange] = useState([new Date(), new Date()]);
    const [roomDateStart, roomDateEnd] = roomDateRange;
    const id = new URLSearchParams(search).get("id");
    const [datas, setDatas] = useState([]);
    const [adult, setAdult] = useState('')
    const [data, setData] = useState({
        checkIn: roomDateStart,
        checkOut: roomDateEnd,
        Adults: '',
        children: ''
      });
    
      useEffect(() => {
        const [adultValue, childrenValue] = adult.split(',');
        setData({
          checkIn: roomDateStart,
          checkOut: roomDateEnd,
          Adults: adultValue,
          children: childrenValue
        });
      }, [roomDateStart, roomDateEnd, adult]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await api.room.getmandirRooms(id);
                if (Array.isArray(result.data.data)) {
                  setDatas(result.data.data);
                } else {
                  console.error('API response is not an array:', result);
                }
              } catch (error) {
                console.error('Error fetching data:', error);
              }
            }
        fetchData();
    }, [id])

    const hello = async () => {
        console.log({data});
        try {
          const response = await api.room.searchroom(data);
          if (response.data.data && response.data.data.length > 0) {
            console.log(response.data.data);
            localStorage.setItem("roomDateRange", roomDateRange)
            localStorage.setItem("adult", adult)
            navigate('/checkout')
            window.scrollTo(0, 0)
          } else {
            console.warn('No data found in the response');
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Room not found',
          })
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
    }
  
    return (
        <div>
            <div className='card-section'>
                <ul className="nav tabs nav-tabs card-tabs" id="myTab" role="tablist" style={{ borderBottom: "none" }}>
                    {/* <div className="w-50">
                        <li className="nav-item active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">
                            <Link className="nav-link px-0 py-1 " style={{ border: "none" }}>Book</Link>
                        </li>
                    </div> */}
                    {/* <div className="w-50">
                        <li className="nav-item" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">
                            <Link className="nav-link px-0 py-1" style={{ border: "none" }}>Inquiry</Link>
                        </li>
                    </div> */}
                </ul>
                <div className="tab-content" id="myTabContent">
                    <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                        <div className="card card-form row px-3 " style={{ border: "none" }}>
                            <div className='col-md-4'>
                                <div className="checkin">Check In - Out</div>
                                <ReactDatePicker
                                    selectsRange={true}
                                    startDate={templeDateStart}
                                    endDate={templeDateEnd}
                                    onChange={(update) => {
                                        setTempleDateRange(update);
                                    }}
                                    withPortal
                                />
                            </div>
                            <div className='col-md-4 p-0'>
                                <form className="form-floating h-100">
                                    <select className="form-select ps-3 field h-100 guestSelector" style={{ borderTop: "none", borderLeft: "1px solid #D7DCE3", borderRight: "1px solid #D7DCE3" }} id='guestSelectorTemple' aria-label="Default select example" onChange={(e) => setAdult(e.target.value)}>
                                    <option selected value="1,0">1 Adult - 0 Children</option>
                                    <option value="2,0">2 Adult - 0 Children</option>
                                    <option value="1.1">1 Adult - 1 Children</option>
                                    <option value="1,2">1 Adult - 2 Children</option>
                                    <option value="2,1">2 Adult - 1 Children</option>
                                    <option value="2,2">2 Adult - 2 Children</option>
                                    </select>
                                    <label for="guestSelectorTemple" className='ps-3'><p className='mb-0 pt-2 text-secondary'>Guest</p></label>
                                </form>
                            </div>
                            <div className="ps-4 d-flex justify-content-center align-items-center col-md-4">
                                <button type="button" className="btn btn-primary"  onClick={hello}>Check Availability</button>
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane fade " id="profile" role="tabpanel" aria-labelledby="profile-tab">
                        <div className="card card-form row px-3" style={{ border: "none" }}>
                            <div className='col-md-4'>
                                <div className="checkin">Check In - Out</div>
                                <ReactDatePicker
                                    selectsRange={true}
                                    startDate={roomDateStart}
                                    endDate={roomDateEnd}
                                    onChange={(update) => {
                                        setRoomDateRange(update);
                                    }}
                                    withPortal
                                />
                            </div>
                            <div className='col-md-4 p-0'>
                                <form className="form-floating h-100">
                                    <select className="form-select ps-3 field h-100 guestSelector" style={{ borderTop: "none", borderLeft: "1px solid #D7DCE3", borderRight: "1px solid #D7DCE3" }} id='guestSelectorRoom' aria-label="Default select example" onChange={(e) => setAdult(e.target.value)}>
                            <option selected value="1,0">1 Adult - 0 Children</option>
                            <option value="2,0">2 Adult - 0 Children</option>
                            <option value="1.1">1 Adult - 1 Children</option>
                            <option value="1,2">1 Adult - 2 Children</option>
                            <option value="2,1">2 Adult - 1 Children</option>
                            <option value="2,2">2 Adult - 2 Children</option>
                                    </select>
                                    <label for="guestSelectorRoom" className='ps-3'><p className='mb-0 pt-2 text-secondary'>Guest</p></label>
                                </form>
                            </div>
                            <div className="ps-4 d-flex justify-content-center align-items-center col-md-4">
                                <button type="button" className="btn button1 btn-primary" onClick={hello}>Check Availability</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="accordion accordion-flush py-3" id="accordionRoom">
                <div className="accordion-item">
                    <h2 className="accordion-header">
                        <button className="accordion-button collapsed px-0" style={{ fontWeight: "600" }} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                            Room
                        </button>
                    </h2>
                    <div id="flush-collapseOne" className="accordion-collapse collapse" data-bs-parent="#accordionRoom">
                        <div className="accordion-body px-0 pt-0">
                        {datas &&
                          datas.map((element, i) => (
                          <HorizontalRoomDetails key={i} {...element} />
                         ))}    
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MandirRoomSearch