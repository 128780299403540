import React from "react";

export default function Information({title,value,pt=false,mb=false}){
    return(
        <>
            <div className={`${pt ? 'pt-4' : ''} row  px-3`}>
                <div className="col-md-6 small-font" style={{ color: "#1A2B48" }}>{title}</div>
                <div className="col-md-6 small-font text-secondary">{value}</div>
            </div>
            <hr className={`${mb ? "mt-4 mb-5" : "my-4"} mx-3 `} />
        </>
    )
} 