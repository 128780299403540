import React, { useState } from 'react';
// import maindirImg from '../images/mandirDetails/mandir.png';
import { FaShare, FaYoutube } from 'react-icons/fa';
import { AiOutlineHeart } from 'react-icons/ai';
import { HiOutlineArrowRight } from 'react-icons/hi';
import { BsArrowsAngleExpand } from 'react-icons/bs';
// import mapImage from '../images/mandirDetails/map.png';
import map from "../images/gujarat/map.png";
import "./MandirDetail.css";
import { useLocation } from 'react-router-dom';
import Temple from '../components/Temple';
import orangestar from "../images/gujarat/orangestar.png";
import MandirTabDetails from '../components/MandirTabDetails';
import MandirRoomSearch from '../components/MandirRoomSearch';
import { useDispatch, useSelector } from 'react-redux';
import { updateMandirDetails } from '../store/mandirDetails';
import { useEffect } from 'react';
// import { updateCity } from '../store/city';
import api, { BASE_URL } from "../api";

const MandirDetail = () => {
  const search = useLocation().search;
  const id = new URLSearchParams(search).get("id");
  const dispatch = useDispatch();
  const token = useSelector((state) => state.user.token);
  const mandirDetail = useSelector((state) => state.mandirDetails.mandirDetails);
  const maxStars = 5;
  // const cityMandir = useSelector((state) => state.mandir.cityMandir);

  useEffect(() => {
    api.mandir.getone(id).then(res => {
      if (res.data?.data) {
        dispatch(updateMandirDetails(res.data.data))
        window.scrollTo(0, 0)
      }
    })
  }, [token, dispatch, id]);

  // useEffect(() => {
  //   if (token && mandirDetail?.cityId) {
  //     api.mandir.cityWise(mandirDetail?.cityId).then(res => {
  //       if (res.data?.data) {
  //         dispatch(updateCity(res.data.data))
  //       }
  //     })
  //   }
  // }, [token, dispatch, mandirDetail])

  const [data, setData] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await api.mandir.cityWise(mandirDetail?.cityId);
                const filteredData = result.data.data.filter(item => item.id !== mandirDetail?.id)
                  setData(filteredData);
              } catch (error) {
                console.error('Error fetching data:', error);
              }
            }
        fetchData();
    }, [mandirDetail?.cityId, mandirDetail?.id])

  return (
    <div className='mandir-details'>
      <div className="row mx-0 px-0">
        <div className="col-md-8 px-0 mx-0">
          <div id="carouselRoom" className="carousel slide">
            <div className="carousel-inner">
              <div className="carousel-item active">
                <img src={BASE_URL + "images/" + mandirDetail.image} className="d-block w-100" alt="mandirImage" />
              </div>
            </div>
            <div className="carousel-control-next" style={{ width: "25px", height: "25px" }} type="button" data-bs-target="#carouselRoom" data-bs-slide="next">
              <HiOutlineArrowRight />
            </div>
            <div className="top-up">
              <div role='button' className="share circular-icon"><FaShare /></div>
              <div role='button' className="youtube circular-icon"><FaYoutube /></div>
              <div role='button' className="like circular-icon"><AiOutlineHeart /></div>
            </div>
            <div className="bottom-down">
              <div role='button' className="expand square-icon"><BsArrowsAngleExpand /></div>
            </div>
          </div>
        </div>
        <div id='mandir_map' style={{minHeight: "250px"}} className="col-md-4 px-0 mx-0">
          <div class="mapouter" style={{ width: '100%', height: '100%' }}>
            <div class="gmap_canvas" style={{ width: '100%', height: '100%' }}>
              <iframe
                title="map"
                className='responsive-iframe'
                id="gmap_canvas"
                src={`https://maps.google.com/maps?q=${(`${mandirDetail.mandirName}, ${mandirDetail.manCity}, ${mandirDetail.manState}, ${mandirDetail.manCountry}`).replaceAll(" ", "+")}&t=&z=13&ie=UTF8&iwloc=&output=embed`}
                frameborder="0"
                scrolling="no"
                marginheight="0"
                marginwidth="0"
                style={{ width: '100%', height: '100%' }}
              ></iframe>
              {/* <a href="https://www.tabclocktab.com/">clock tab</a><br /><a
                href="https://www.clock-alarm.com/"
              >
              </a>
              <br />
              <a href="https://www.ongooglemaps.com">google maps embed</a> */}
            </div>
          </div>
        </div>
      </div>

      <div className='room-container container pt-5 px-3'>
        <div className="row" style={{ borderBottom: "1px solid #EAEEF3" }}>
          <div className="col-md-9 pb-5">
            <div className="head">
              <div className="row">
                <div className="col-md-8">
                <div>
      {[...Array(maxStars)].map((_, index) => (
        <img
          key={index}
          src={orangestar}
          alt="orangestar"
          className="me-1"
          style={{ maxWidth: "12px", filter: `brightness(${index < mandirDetail?.reviews ? '100%' : '20%'})` }}
        />
      ))}
    </div>
                  <h2 className='title'>{mandirDetail?.mandirName}</h2>
                  <div className='sub-title d-flex align-items-center'>
                    <img src={map} alt="map" width="16px" className='me-1' />
                    {mandirDetail?.manCity}, {mandirDetail?.manCountry} <a href="#mandir_map" className='ms-1 text-decoration-none'>View on map</a>
                  </div>
                </div>
                <div className="col-md-4 py-3 p-md-0 rating">
                  <div className="d-flex flex-wrap align-items-center justify-content-end">
                    <div className="me-2">
                      <h3 className='title'>Excellent</h3>
                      <div className="sub-title">from {mandirDetail?.reviews} reviews</div>
                    </div>
                    <div className="">
                      <button type='button' className='btn btn-primary'><span>{mandirDetail?.reviews}</span>/5</button>
                    </div>
                  </div>
                  <div className="recomandation text-secondary" style={{ fontSize: "14px" }}>
                    100% guests recommend
                  </div>
                </div>
              </div>
            </div>
            <MandirTabDetails tabs={["Description", "Rules", "Reviews"]} mandirDetails={mandirDetail} />
            <MandirRoomSearch />
          </div>
          <div className="col-md-3 pb-5">
            <div className="card side card-owner">
              <div className="title  me-1">Information Contact </div>
              <div className="text-primary pt-3 pb-2">Email</div>
              <div className="text-secondary">{mandirDetail?.manEmail}</div>
              <div className="text-primary pt-3 pb-2">Website</div>
              <div className="text-secondary">{mandirDetail?.manWebsite}</div>
              <div className="text-primary pt-3 pb-2">Phone</div>
              <div className="text-secondary">{mandirDetail?.phone}</div>
              <div className="text-primary pt-3 pb-2">Fax</div>
              <div className="text-secondary">{mandirDetail?.fax}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="container pb-md-5 mb-md-5">
        <div className="row">
          <div className="dark-font text-center py-5" style={{ color: "#1A2B48" }} >Nearby Temple</div>
          {data.map((data) => <Temple {...data} logo={true} />)}

        </div>
      </div>
 
    </div>
  )
}

export default MandirDetail