import React from 'react';
import "./Table.css"

const Table = ({ title,data }) => {
    return (
        <div className='table-container'>
            {title && <div className='table-title'>{title}</div>}
            <table className="table">
                <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">Discount group</th>
                        <th scope="col">From No. days</th>
                        <th scope="col">To No. days</th>
                        <th scope="col">Value</th>
                    </tr>
                </thead>
                <tbody>
                    {data.discount_name?.map((e,i)=>{
                        return <tr key={i}>
                            <th scope="row">{i +1}</th>
                            <td>{e}</td>
                            <td>{data.from_no_days[i]}</td>
                            <td>{data.to_no_days[i]}</td>
                            <td>%{data.value[i]}</td>
                        </tr>
                    })}
                    {/* <tr>
                        <th scope="row">2</th>
                        <td>P2</td>
                        <td>4</td>
                        <td></td>
                        <td>%</td>
                    </tr>
                    <tr>
                        <th scope="row">3</th>
                        <td>P3</td>
                        <td>5</td>
                        <td></td>
                        <td>%</td>
                    </tr>
                    <tr>
                        <th scope="row">4</th>
                        <td>P4</td>
                        <td>6</td>
                        <td></td>
                        <td>%</td>
                    </tr> */}
                </tbody>
            </table>
        </div>
    )
}

export default Table