import axios from "axios";
import QueryString from "qs";
export const BASE_URL = process.env.REACT_APP_URL;
axios.defaults.baseURL = BASE_URL;

export const addAxiosToken = () => {
	axios.defaults.headers.common[
		"Authorization"
	] = `Bearer ${localStorage.getItem("token")}`;
};

const convertToFormData = (obj) => {
	const formData = new FormData();
	for (const key in obj) {
		if (Object.hasOwnProperty.call(obj, key)) {
			const element = obj[key];
			formData.append(key, element);
		}
	}
	return formData;
};

export const user = {
	login: async (data) => {
		return axios.post("user/login", data);
	},
	register: (data) => {
		return axios.post("user/register", data);
	},
	forgotpassword: (data) => {
		return axios.post("user/forgot-password", data);
	},
	resetpassword: (data) => {
		return axios.post("user/reset-password", data);
	},
	list: () => {
		addAxiosToken();
		return axios.get("user/getalluser");
	},
	create: (data) => {
		addAxiosToken();
		return axios.post("user/register", QueryString.stringify(data));
	},
	update: (id, data) => {
		addAxiosToken();
		return axios.put("user/updateuser/" + id, QueryString.stringify(data));
	},
	delete: (id) => {
		addAxiosToken();
		return axios.delete("user/deleteuser/" + id);
	},
};

export const city = {
	list: () => {
		addAxiosToken();
		return axios.get("city/getallcity");
	},
	create: (data) => {
		addAxiosToken();
		return axios.post("city/create", convertToFormData(data));
	},
	getone: (id) => {
		addAxiosToken();
		return axios.put("city/getoneuser/" + id);
	},
	update: (id, data) => {
		addAxiosToken();
		return axios.put("city/updatecity/" + id, convertToFormData(data));
	},
	delete: (id) => {
		addAxiosToken();
		return axios.delete("city/deletecity/" + id);
	},
};

export const mandir = {
	list: () => {
		addAxiosToken();
		return axios.get("mandir/getallmandir");
	},
	search: (data) => {
		addAxiosToken();
		return axios.get("mandir/searchmandir?" + QueryString.stringify(data));
	},
	searchcity: (data) => {
		addAxiosToken();
		return axios.get("mandir/searchcity?" + QueryString.stringify(data));
	},
	getone: (data) => {
		addAxiosToken();
		return axios.get("mandir/getone/" + data);
	},
	cityWise: (data) => {
		addAxiosToken();
		return axios.get("mandir/getMandir/" + data);
	},
	create: (data) => {
		addAxiosToken();
		return axios.post("mandir/create", convertToFormData(data));
	},
	update: (id, data) => {
		addAxiosToken();
		return axios.put("mandir/updateMandir/" + id, convertToFormData(data));
	},
	updateRating: (id, reviews) => {
		addAxiosToken();
		return axios.put("mandir/updateRating/" + id + '?' + QueryString.stringify(reviews));
	},
	delete: (id) => {
		addAxiosToken();
		return axios.delete("mandir/deleteMandir/" + id);
	},
};

export const room = {
	list: () => {
		return axios.get("room/getallroom", {
			headers: {
				Authorization: `Bearer ${localStorage.getItem("token")}`,
			},
		});
	},
	searchroom: (data) => {
		addAxiosToken();
		return axios.get("room/searchroom", { params: data });
	},
	getone: (data) => {
		return axios.get("room/getoneroom/" + data, {
			headers: {
				Authorization: `Bearer ${localStorage.getItem("token")}`,
			},
		});
	},
	getmandirRooms: (data) => {
		return axios.get("room/getmandirRooms/" + data, {
			headers: {
				Authorization: `Bearer ${localStorage.getItem("token")}`,
			},
		});
	},
	create: (data) => {
		addAxiosToken();
		return axios.post("room/createroom", convertToFormData(data));
	},
	update: (id, data) => {
		addAxiosToken();
		return axios.put("room/updateroom/" + id, convertToFormData(data));
	},
	delete: (id) => {
		addAxiosToken();
		return axios.delete("room/deleteroom/" + id);
	},
};

export const booking = {
	create: (data) => {
		addAxiosToken();
		return axios.post("book/roombook", QueryString.stringify(data));
	},
	getone: (data) => {
		return axios.get("book/getonebooking/" + data, {
			headers: {
				Authorization: `Bearer ${localStorage.getItem("token")}`,
			},
		});
	},
	payment: (data) => {
		return axios.post("payment/addpayments", data);
	},
};

export const message = {
	create: (data) => {
		addAxiosToken();
		return axios.post("message/create-message", QueryString.stringify(data));
	}
};

const api = {
	user,
	city,
	mandir,
	room,
	booking,
	message
};

export default api;
