import React from 'react'
import "./NavigationPanel.css";
import { RiCheckboxBlankFill } from 'react-icons/ri'
// import { Link } from 'react-router-dom';

const NavigationPanel = ({ path = [] }) => {
    return (
        <ul className="nav-panel nav d-flex align-items-center container">
            {path.map((p, key) => <React.Fragment key={key}>
                <li><div to={p.link} className={'nav-link ' + (key !== path.length - 1 ? '' : 'active')}>{p.name}</div></li>
                {key !== path.length - 1 && <RiCheckboxBlankFill className='light-blue-gray' />}
            </React.Fragment>)}
        </ul>
    )
}

export default NavigationPanel