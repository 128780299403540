import React from "react";
import {  useNavigate } from 'react-router-dom';
import { BASE_URL } from "../api";
import { useDispatch, useSelector } from 'react-redux';
import api from '../api';
import { useEffect } from 'react';
import { updateMandir } from '../store/mandir';

const AboutUs = ({element}) => {
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const token = useSelector((state) => state.user.token);
    const mandir = useSelector((state) => state.mandir.mandir);
    useEffect(() => {
        api.mandir.list().then(res=>{
            if (res.data?.data) {
                dispatch(updateMandir(res.data.data))
            }
        })
    }, [token, dispatch]);

    return (
        <div className="row pt-5 justify-content-center">
            {/* <div className="dark-font d-flex justify-content-center mb-4">
                Events
            </div> */}
            <div className="row">
                {mandir?.slice(0, 6).map((element, i) => <div className="col-md-4 py-3" key={i}>
                    {/* <Destination element={element} /> */}
                    <div className="temple-img-des position-relative" onClick={()=>navigate(`/mandirDetail?id=`+ element.id)}>
                <img src={BASE_URL + "images/" + element.image} alt='events' width="100%" height="357px" />
                {/* <div className="opacity: 0.2"></div> */}
                {/* <div className="row justify-content-center position-absolute bottom-0 ">
                    <h3 className="text-light text-center">
                        {element.mandirName}
                    </h3>
                    <ul className="d-flex flex-wrap text-white justify-content-center px-md-0 px-lg-4 px-xl-5 px-4">
                        <span className="pe-3 ms-3">{element.activities} Activities</span>
                        <li className="pe-3 ms-3">{element.cars} Cars</li>
                        <li className="pe-3 ms-3">{element.hotels} Hotels</li>
                        <li className="pe-3 ms-3">{element.rentals} Rentals</li>
                        <li className="pe-3 ms-3">{element.tours} Tours</li>
                    </ul>
                </div> */}
            </div>
                </div>)}
            </div>
        </div>
    )
}

export default AboutUs;