import React, { cloneElement } from "react";
import Header from "./components/Header";
import NavigationPanel from "./components/NavigationPanel";
import Footer from "./components/Footer";

const MainWrapper = (props) => {
  const { children, path, isImage , isTab } = props;
  return (
    <div>
      <Header isImage={isImage} isTab={isTab}/>
      {path && <NavigationPanel path={path} />}
      {cloneElement(children, (props = { ...{ props} }))}
      <Footer />
    </div>
  );
};

export default MainWrapper;
