import React from 'react'
// import hotel from "../images/gujarat/hotel.png";
// import map from "../images/gujarat/map.png";
// import star from "../images/gujarat/fill-star.png";
// import halfstar from "../images/gujarat/star.png";
// import watch from "../images/gujarat/watch.png";
// import charge from "../images/gujarat/charge.png";
// import nobedlogo from "../images/gujarat/no-bed-logo.png";
// import offer from "../images/gujarat/10%.png";
// import { Link } from "react-router-dom";
import TrendingHotelCard from './TrendingHotelCard';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { updateRoom } from '../store/room';
import api from '../api';

// const rooms = [
//     {
//         image: hotel,
//         room: "Two Hour Walking Tour of Manhattan",
//         location: "New York, United States",
//         stars: 4.5,
//         reviews: 2,
//         time: "10 hours",
//         price: "$1.000,00",
//     },
//     {
//         image: nobedlogo,
//         room: "Segway Tour of Washington, D.C.Highlights",
//         location: "California, USA",
//         stars: 5,
//         reviews: 1,
//         time: "3 days",
//         price: "$1.500,00",
//     },
//     {
//         image: nobedlogo,
//         room: "America’s National Parks with Denver",
//         location: "Los Angeles, CA, USA",
//         time: "1 days",
//         price: "$862,20",
//         originalPrice: "$958,00",
//     },
    // {
    //     image: nobedlogo,
    //     room: "Segway Tour of Washington, D.C.Highlights",
    //     location: "California, USA",
    //     stars: 5,
    //     reviews: 1,
    //     time: "3 days",
    //     price: "$1.500,00",
    // },
    // {
    //     image: nobedlogo,
    //     room: "Segway Tour of Washington, D.C.Highlights",
    //     location: "California, USA",
    //     stars: 5,
    //     reviews: 1,
    //     time: "3 days",
    //     price: "$1.500,00",
    // },
    // {
    //     image: nobedlogo,
    //     room: "Segway Tour of Washington, D.C.Highlights",
    //     location: "California, USA",
    //     stars: 5,
    //     reviews: 1,
    //     time: "3 days",
    //     price: "$1.500,00",
    // },
    // {
    //     image: nobedlogo,
    //     room: "Segway Tour of Washington, D.C.Highlights",
    //     location: "California, USA",
    //     stars: 5,
    //     reviews: 1,
    //     time: "3 days",
    //     price: "$1.500,00",
    // },
    // {
    //     image: nobedlogo,
    //     room: "Segway Tour of Washington, D.C.Highlights",
    //     location: "California, USA",
    //     stars: 5,
    //     reviews: 1,
    //     time: "3 days",
    //     price: "$1.500,00",
    // }
// ]

const Trending = () => {
    const dispatch = useDispatch();
    const token = useSelector((state) => state.user.token);
    const room = useSelector((state) => state.room.room);
    useEffect(() => {
        api.room.list().then(res=>{
            if (res.data?.data) {
                dispatch(updateRoom(res.data.data))
            }
        })
    }, [token, dispatch]);
    return (
        <div className="row py-5 justify-content-center">
            <div className="dark-font d-flex" style={{ color: "#1A2B48" }}>
                Trending Rooms
                {/* <span>
                    <div className="dropdown">
                        <button
                            className="btn dropdown-toggle pt-0"
                            type="button"
                            style={{
                                fontWeight: "400",
                                fontSize: "28px",
                                color: "#5191FA",
                            }}
                            id="dropdownMenuButton1"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                        >
                            Rooms
                        </button>
                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                            <li>
                                <Link className="dropdown-item" to="#">
                                    Action
                                </Link>
                            </li>
                            <li>
                                <Link className="dropdown-item" to="#">
                                    Another action
                                </Link>
                            </li>
                            <li>
                                <Link className="dropdown-item" to="#">
                                    Something else here
                                </Link>
                            </li>
                        </ul>
                    </div>
                </span> */}
            </div>
            <div className="row">
                {/* {rooms.map((element, i)=><div className="col-md-6 col-lg-4 py-2 col-xl-3">
                    <TrendingHotelCard {...element}/>
                </div>)} */}
                {room.slice(0, 8).map((element, i)=><div className="col-md-6 col-lg-4 py-2 col-xl-3">
                    <TrendingHotelCard element={element}/>
                </div>)}
            </div>
        </div>
    )
}

export default Trending