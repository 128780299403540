import React from "react";
import truck from "../images/gujarat/truck.png"
import safe from "../images/gujarat/safe.png"
import quality from "../images/gujarat/quality.png"
import aboutus from "../images/gujarat/about-us1.png"
import aboutus1 from "../images/gujarat/about-us.png"
import Constraint from "../components/Constraint";
import SideImageContainer from "../components/SideImageContainer";

const constraints = [
    {
        image: truck,
        title: "Reliable Shipping",
        description: "Green Society provides Canada Post Xpress Shipping right to your doorstep! You can also opt in for shipping insurance. For orders over ₹2000, shipping is free!"
    },
    {
        image: safe,
        title: "You’re Safe With Us",
        description: "Our secure payment system accepts the most common forms of payments making the checkout process quicker! The payments we accept are debit, all major credit cards, and cryptocurrency."
    },
    {
        image: quality,
        title: "Best Quality & Pricing",
        description: "Here at Green Society, we take pride in the quality of our products and service. Our prices are set to ensure you receive your medication at a reasonable price and safely"
    }
]

const sideImages = [
    {
        image: aboutus,
        title: "About Us",
        description: [
            "Welcome to [Company Name]! We're a team of passionate individuals who love providing our customers with high-quality products and excellent service. Our mission is to make your shopping experience as seamless and enjoyable as possible, so you can find exactly what you need without any hassle.",
            "At [Company Name], we believe in offering a wide selection of products that cater to every taste and budget. Whether you're looking for the latest fashion trends, high-tech gadgets, or home decor essentials, we've got you covered. We source our products from trusted suppliers to ensure that you receive only the best quality items."
        ],
        buttonName: "Explore Products"
    },
    {
        image: aboutus1,
        title: "Quality is our top priority. Shop with confidence.",
        description: [
            "At [Company Name], we're committed to providing our customers with the highest quality products on the market. We believe that when you buy from us, you should be able to trust that you're getting a product that will meet or exceed your expectations. That's why we go above and beyond to source only the best materials and work with trusted manufacturers who share our commitment to quality.",
            "We believe that quality is about more than just the materials used to make a product."    
        ],
        buttonName: "Explore Products",
        side: "right",
        small: true
    }
]

const AboutUs = () => {
    return (
        <div className="container mb-5 pt-5">
            <div className="row mb-3 mb-lg-5">
                {constraints.map((element, i)=>(<div key={i} className="col-md-6 col-lg-4 mb-3">
                    <Constraint {...element} />
                </div>))}
            </div>
            {sideImages.map((element, i)=><SideImageContainer key={i} {...element} />)}
        </div>
    )
}

export default AboutUs;
