import React from 'react'

const Constraint = ({image, title, description}) => {
    return (
        <div className="row">
            <div className="col-md-4 col-3">
                <img src={image} alt={title} width="100%" />
            </div>
            <div className="col-md-8 col-9">
                <div className="title">{title}</div>
                <div className="pt-3 text-secondary" >{description}</div>
            </div>
        </div>
    )
}

export default Constraint