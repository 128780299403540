import React from 'react'

const SideImageContainer = ({ image, title, description = [], buttonName, side = "left", small = false }) => {
    let titleStyle = {
        color: "#1A2B48",
    };
    titleStyle = small ? { ...titleStyle, fontSize: "28px" } : titleStyle;

    return (
        <div className={"row pt-3 pt-lg-5 px-3 " + (small ? "pb-4" : "mb-lg-3")}>
            {side === "left" && <div className="col-lg-6">
                <img src={image} alt="aboutus" width="100%" height="100%" />
            </div>}
            <div className={"col-lg-6 " + (small ? "pe-3" : "")}>
                <div className="large-font mb-2" style={titleStyle}>{title}</div>
                {description.map((text, i) => <div key={i} className="small-font pt-2 text-secondary">{text}</div>)}
                <div className="row pt-4">
                    <div className="col-lg-6 mb-3">
                        <button type="button" className="btn btn-primary small-font" style={{ background: "#5191FA", borderRadius: "12px", border: "none", padding: "11px 34px" }}>{buttonName}</button>
                    </div>
                </div>
            </div>
            {side === "right" && <div className="col-lg-6">
                <img src={image} alt="aboutus" width="100%" height="100%" />
            </div>}
        </div>
    )
}

export default SideImageContainer