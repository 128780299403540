import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom';
import { FaStar } from 'react-icons/fa';
import api from '../api';

const MandirTabDetails = ({ tabs = [], mandirDetails }) => {
    const search = useLocation().search;
    const id = new URLSearchParams(search).get("id");
        const [rating, setRating] = useState(0);
      
        const handleStarClick = (selectedRating) => {
          setRating(selectedRating);
        };

        const handleSubmit = async () => {
            try {
                await api.mandir.updateRating(id, { reviews: rating });
                // Reload the page after successful submission
                window.location.reload();
              } catch (error) {
                console.error('Error submitting rating:', error);
                // Handle error scenarios
              }
        }
    return (
        <div>
            <div className="py-4 mt-1">
                <ul className="nav tabs nav-tabs" id="myTab" role="tablist" style={{ borderBottom: "none" }}>
                    {tabs.map((element, i) => (<li key={i} className="nav-item">
                        <Link className={"nav-link px-0 pb-4 me-5" + (i ? "" : " active")} id={`${element}-tab`} data-bs-toggle="tab" data-bs-target={`#${element}`} type="button" role="tab" aria-controls={element} aria-selected="true" style={{ border: "none" }}>{element}</Link>
                    </li>))}
                </ul>
            </div>
            <div className="tab-content" id="myTabContent">
                {tabs.map((element, i) => (<div key={i} className={"tab-pane fade show" + (i ? "" : " active")} id={element} role="tabpanel" aria-labelledby={`${element}-tab`}>
                    <div className="main-description pt-3 pb-5" style={{ maxWidth: "608px" }}>
                        {element === "Description" && <p>{mandirDetails?.desc}</p>}
                        {/* {element === "Facilities" && <p>{mandirDetails?.facilities}</p>} */}
                        {element === "Rules" && <p>{mandirDetails?.rules}</p>}
                        {/* {element === "Reviews" && <p>{mandirDetails?.reviews}</p>} */}
                        {element === "Reviews" &&
                        <div>
      <p>Select a star rating:</p>
      {[1, 2, 3, 4, 5].map((star) => (
        <FaStar
          key={star}
          className={star <= rating ? 'star selected' : 'star'}
          style={{ color: `rgba(255, 165, 0, ${star <= rating ? '1' : '0.3'})`, fontSize: '20px' }}
          onClick={() => handleStarClick(star)}
        />
      ))}
      <br />
      <button type="button" className="btn btn-primary" style={{ marginTop: '20px', width: '130px' }} onClick={handleSubmit}>Submit</button>
    </div>
                  }
                    </div>
                </div>))}
            </div>

        </div>
    )
}

export default MandirTabDetails