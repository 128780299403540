import { configureStore, combineReducers } from "@reduxjs/toolkit";
import user from "./user";
import city from "./city";
import mandir from "./mandir";
import mandirDetails from "./mandirDetails";
import room from "./room";
import checkout from "./checkout";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";

const reducers = combineReducers({
  user,
  city,
  mandir,
  mandirDetails,
  room,
  checkout
});

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
});
