import React from 'react'
import childIcon from '../images/roomDetail/child.svg'
import peopleIcon from '../images/roomDetail/people.svg'
import bedIcon from '../images/roomDetail/bed.svg'
import roomSizeIcon from '../images/roomDetail/roomSize.svg'
import { useNavigate } from 'react-router-dom'
import { BASE_URL } from '../api';

const HorizontalRoomDetails = ({image, name, size, beds, Adults, children, id}) => {
    const navigate = useNavigate();

    return (
        <div className="border hotel-card row w-100 align-items-center m-0 mb-3">
            <div className="col-md-4 p-0">
                <div className="image-container">
                    <img src={BASE_URL + "images/" + image} className="" width="100%" alt="roomImage" style={{ borderTopLeftRadius: "8px", borderBottomLeftRadius: "8px" }} />
                </div>
            </div>
            <div className="col-md-5 py-3 p-md-0 px-md-3">
                <div className="room-info ps-3">
                    <div className="title mb-4">{name}</div>
                    <div className="config d-flex align-items-center flex-wrap pt-2 me-2">
                        <div className="option d-flex align-items-center flex-column me-2">
                            <div className="image-container">
                                <img src={roomSizeIcon} alt="roomSizeIcon" width="24px" height="24px" />
                            </div>
                            <div className="desc m-0">{size}m<sup>2</sup></div>
                        </div>
                        <div className="option d-flex align-items-center flex-column me-2">
                            <div className="image-container">
                                <img src={bedIcon} alt="bedIcon" width="24px" height="24px" />
                            </div>
                            <div className="desc m-0">x{beds}</div>
                        </div>
                        <div className="option d-flex align-items-center flex-column me-2">
                            <div className="image-container">
                                <img src={peopleIcon} alt="peopleIcon" width="24px" height="24px" />
                            </div>
                            <div className="desc m-0">x{Adults}</div>
                        </div>
                        <div className="option d-flex align-items-center flex-column me-2">
                            <div className="image-container">
                                <img src={childIcon} alt="children" width="24px" height="24px" />
                            </div>
                            <div className="desc m-0">x{children}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-md-3 pt-2 pb-4 p-md-0">
                <div className="button-container px-4 w-100 px-md-3">
                    <button type="button" className="btn btn-primary" onClick={() => navigate('/roomDetail?id=' + id)}>Show Price</button>
                </div>
            </div>
        </div>
    )
}

export default HorizontalRoomDetails