import React, { useEffect, useState } from 'react'
import item from "../images/gujarat/itemcard.png"
import PaymentItem from '../components/PaymentItem';
import Swal from 'sweetalert2';
import api from '../api';
import { useDispatch, useSelector } from 'react-redux';
import { updateCheckout } from '../store/checkout';
// import startPayment from '../payments';

const Checkout = () => {
    const dispatch = useDispatch();
    const roomDetail = useSelector((state) => state.room.roomdetails);
    // const userDetail = useSelector((state) => state.user.userdata);
    const totaldays = localStorage.getItem("days")
    const totalAmount = roomDetail.price*totaldays

    const startDate = localStorage.getItem("roomDateRange").split(",")[0]
    const endDate = localStorage.getItem("roomDateRange").split(",")[1]
    const date1 = new Date(startDate)
    const date2 = new Date(endDate)

    const abc = date1.toLocaleDateString('en-GB', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
    })

    const cde = date2.toLocaleDateString('en-GB', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
    })

    const adult = localStorage.getItem("adult")

    const [form, setForm] = useState({
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        address1: "",
        address2: "",
        city: "",
        state: "",
        zipcode: "",
        country: "",
        special_requirements: "",
        payment_status: true,
        roomId: roomDetail.id,
        checkIn: date1,
        checkOut: date2,
        adminId: roomDetail.userId,
        totalAmount: totalAmount
    });

    const changeValue = (e) => {
        const key = e.target.name;
        let value = e.target.value;
        if (
            key === "phone" ||
            key === "zipcode"
        ) {
            if (Number(value) !== "NaN") {
                if (key === "phone" && value.length >= 11) {
                    return
                }
                if (key === "zipcode" && value.length >= 7) {
                    return
                }
                setForm(prev => ({ ...prev, [key]: value }))
            }
        } else {
            setForm(prev => ({ ...prev, [key]: value }))
        }
    }
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        // api.booking.create(form).then((res) => {
        //     localStorage.setItem("bookingId", res.data.data.id)
        //     if (res.data?.data) {
        //         Swal.fire(
        //             'Created',
        //             'Data Created Successfully!',
        //             'success'
        //         ).then((response) => {
        //             const newCheckout = res.data.data;
        //             dispatch(updateCheckout(newCheckout))
        //             // startPayment(roomDetail.price, userDetail._id, newCheckout.email, newCheckout.phone)
        //         })
        //     } else {
        //         Swal.fire({
        //             icon: 'error',
        //             title: 'Oops...',
        //             text: res.data.msg,
        //         })
        //     }
        // })

        // console.log({bookingId});
        // const bookingId = response.data.id;
        // localStorage.setItem("bookingId", bookingId)

        // const order = await createOrder(params);

        const options = {
            key: "rzp_test_DXeKDDIUoxd4rr",
            amount: totalAmount*100,
            currency: "INR",
            name: "Atithi Bhavan",
            description: "Atithi bhavan room",
            handler: (res) => {
                console.log(res);
                api.booking.create(form).then((res) => {
                    localStorage.setItem("bookingId", res.data.data.id)
                    if (res.data?.data) {
                        Swal.fire(
                            'Created',
                            'Data Created Successfully!',
                            'success'
                        ).then((res) => {
                            const newCheckout = res.data.data;
                            dispatch(updateCheckout(newCheckout))
                            // startPayment(roomDetail.price, userDetail._id, newCheckout.email, newCheckout.phone)
                        })
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: res.data.msg,
                        })
                    }
                })
                window.location.href = '/afterCheckout';
            },
            prefill: {
                name: form.firstName,
                email: form.email,
                contact: form.phone
            },
            notes: {
                address: "Surat, Gujrat",
            },
        };

        const rzpay = new window.Razorpay(options);
        rzpay.open();
    }

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://checkout.razorpay.com/v1/checkout.js';
        script.async = true;
        document.body.appendChild(script);
    }, []);

    const Bookingitem = [
        {
            name: "Room",
            data: `${roomDetail.roomName}`
        },
        {
            name: "Date",
            data: abc + " - " + cde
        },
        {
            name: "Number of Night",
            data: totaldays + " Night"
        },
        {
            name: "Number of Room",
            data: "1 Room(S)"
        },
        {
            name: "Number of Adult",
            data: adult
        },
        // {
        //     name: "Deposit percent",
        //     data: "99 %"
        // }
    ]
    return (
        <div className="container py-md-3 my-md-2">
            <div className="row mb-5">
                <div className="col-md-8">
                    <div className="row px-2">
                        <div className="normal-font pt-4 pb-3 fw-bold" style={{ fontSize: '21px', color: "#1A2B48" }}>Booking Submission</div>
                        <hr />
                        <div className="pt-md-4 pt-2">
                            <form >
                                <div className="form-row row pb-4">
                                    <div className="form-group col-md-6 pb-3 pb-md-0">
                                        <label for="fname" className="pb-3">First Name*</label>
                                        <input value={form.firstName} name="firstName" onChange={changeValue} type="text" className="form-control form-control-lg" id="fname" placeholder="First Name" required />
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label for="lname" className="pb-3">Last Name*</label>
                                        <input value={form.lastName} name="lastName" onChange={changeValue} type="text" className="form-control form-control-lg" id="lname" placeholder="Last Name" required />
                                    </div>
                                </div>
                                <div className="form-row row pb-4 pt-1">
                                    <div className="form-group col-md-6 pb-3 pb-md-0">
                                        <label for="email" className="pb-3 ">Email*</label>
                                        <input value={form.email} name="email" onChange={changeValue} type="email" className="form-control form-control-lg" id="email" placeholder="email@domain.com" required />
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label for="phone" className="pb-3">Phone*</label>
                                        <input value={form.phone} name="phone" onChange={changeValue} type="number" className="form-control form-control-lg" id="phone" placeholder="Your Phone" required />
                                    </div>
                                </div>
                                <div className="form-row row pb-4 pt-1">
                                    <div className="form-group col-md-6 pb-3 pb-md-0">
                                        <label for="Add1" className="pb-3">Address Line 1*</label>
                                        <input value={form.address1} name="address1" onChange={changeValue} type="text" className="form-control form-control-lg" id="Add1" placeholder="Your Address Line 1"  required/>
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label for="Add2" className="pb-3">Address Line 2*</label>
                                        <input value={form.address2} name="address2" onChange={changeValue} type="text" className="form-control form-control-lg" id="Add2" placeholder="Your Address Line 2"  required/>
                                    </div>
                                </div>
                                <div className="form-row row pb-4 pt-1">
                                    <div className="form-group col-md-6 pb-3 pb-md-0">
                                        <label for="city" className="pb-3">City*</label>
                                        <input value={form.city} name="city" onChange={changeValue} type="text" className="form-control form-control-lg" id="city" placeholder="Your City"  required/>
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label for="state" className="pb-3">State/Province/Region*</label>
                                        <input value={form.state} name="state" onChange={changeValue} type="text" className="form-control form-control-lg" id="state" placeholder="Your State"  required/>
                                    </div>
                                    {/* <div className="form-group col-md-6">
                                        <label for="state" className="pb-3">State/Province/Region</label>
                                        <select id="state" className="form-control form-control-lg">
                                            <option selected>Choose...</option>
                                            <option>...</option>
                                        </select>
                                    </div> */}
                                </div>
                                <div className="form-row row pb-4 pt-1">
                                    <div className="form-group col-md-6 pb-3 pb-md-0">
                                        <label for="zipcode" className="pb-3">ZIP Code/Postal Code*</label>
                                        <input value={form.zipcode} name="zipcode" onChange={changeValue} type="number" className="form-control form-control-lg" id="zipcode" placeholder="Your State"  required/>
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label for="country" className="pb-3">Country*</label>
                                        <input value={form.country} name="country" onChange={changeValue} type="text" className="form-control form-control-lg" id="country" placeholder="Your country" required />
                                    </div>
                                </div>
                                <div className="form-group pt-1">
                                    <label for="requirment" className="pb-3">Special Requirements</label>
                                    <textarea className="form-control form-control-lg" id="requirment" rows="5" placeholder="Special Requirements" value={form.special_requirements} name="special_requirements" onChange={changeValue} />
                                </div>
                            </form>
                            <buttn className="border-0 w-auto btn btn-primary py-3 px-5 mt-5" onClick={handleSubmit} type="submit" style={{ borderRadius: "8px" }} >Pay & Submit</buttn>
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="row px-2">
                        <div className="normal-font pb-3 fw-bold pt-4" style={{ fontSize: '21px', color: "#1A2B48" }}>Your Booking</div>
                        <div className="mx-md-3" style={{ border: "1px solid rgb(215, 220, 227)", borderRadius: "8px" }}>
                            <div className="row p-4 align-items-center" style={{ borderBottom: "1px solid #EAEEF3" }}>
                                <div className="col-lg-8">
                                    <div className="text-500" style={{ color: "#1A2B48" }}>{roomDetail.hotelName}</div>
                                    {/* <div className="d-flex pt-2 align-items-center">
                                        <img src={map} alt='map' style={{ maxWidth: "23px" }} />
                                        <div className="normal-font text-secondary ps-2"> Virginia, USA</div>
                                    </div> */}
                                </div>
                                <div className="col-lg-4">
                                    <img src={item} alt="item" width="100%" />
                                </div>
                            </div>
                            <div className="row p-4" style={{ color: "#1A2B48", borderBottom: "1px solid #EAEEF3" }}>
                                {Bookingitem.map((element, index) => {
                                    return <PaymentItem {...element} />
                                })}
                            </div>
                            {/* <div className="row px-4">
                                <div className="normal-font pt-4 pb-3" style={{ fontSize: '21px', color: "#1A2B48" }}>Coupon Code</div>
                                <div className="col-lg-8 pb-3 pb-lg-0" >
                                    <input type="email" className="form-control" id="code" aria-describedby="code" />
                                </div>
                                <div className="col-lg-4 px-lg-0">
                                    <buttn className="border-0 w-100 btn btn-primary">Apply</buttn>
                                </div>
                            </div> */}
                            <div className="row p-4 pb-5" style={{ color: "#1A2B48" }}>
                                <div className="col-lg-5 pt-3 small-font">Subtotal</div>
                                <div className="col-lg-7 pt-3 text-lg-end text-secondary small-font">₹{totalAmount}</div>
                                <div className="col-lg-5 pt-3 small-font">Total</div>
                                <div className="col-lg-7 pt-3 text-lg-end text-secondary small-font">₹{totalAmount}</div>
                                {/* <div className="col-lg-5 pt-3 small-font">Deposit</div>
                                <div className="col-lg-7 pt-3 text-lg-end text-secondary small-font">$346,50</div> */}
                                <div className="col-lg-5 pt-3 text-500" style={{ fontSize: "18px" }}>Pay Amount</div>
                                <div className="col-lg-7 pt-3 text-lg-end text-secondary small-font" style={{ fontSize: "18px" }}>₹{totalAmount}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Checkout