import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import MainWrapper from "./MainWrapper";
import path from "./path";

import Home from "./pages/Home";
import AboutUs from "./pages/AboutUs";
import Events from "./pages/Events";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsConditions from "./pages/Terms&Conditions";
import ReturnRefundPolicy from "./pages/Return&RefundPolicy";
import AfterCheckout from "./pages/AfterCheckout";
import Checkout from "./pages/Checkout";
import ContactUs from "./pages/ContactUs";
import PageNotFound from "./pages/PageNotFound";
import Mandir from "./pages/Mandir";
import MandirDetail from "./pages/MandirDetail";
import RoomDetail from "./pages/RoomDetail";
import Resetpassword from "./pages/Resetpassword";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route index element={<MainWrapper isImage={true} isTab={true}><Home /></MainWrapper>} />
        <Route path='/aboutUs' element={<MainWrapper path={path.aboutUs}><AboutUs /></MainWrapper>} />
        <Route path='/events' element={<MainWrapper path={path.events}><Events /></MainWrapper>} />
        <Route path='/privacyPolicy' element={<MainWrapper path={path.PrivacyPolicy}><PrivacyPolicy /></MainWrapper>} />
        <Route path='/terms&Conditions' element={<MainWrapper path={path.TermsConditions}><TermsConditions /></MainWrapper>} />
        <Route path='/Return&RefundPolicy' element={<MainWrapper path={path.ReturnRefundPolicy}><ReturnRefundPolicy /></MainWrapper>} />
        <Route path="/afterCheckout" element={<MainWrapper path={path.afterCheckout}><AfterCheckout /></MainWrapper>} />
        <Route path="/checkout" element={<MainWrapper path={path.checkout}><Checkout /></MainWrapper>} />
        <Route path='/contactUs' element={<MainWrapper path={path.contactUs}><ContactUs /></MainWrapper>} />
        <Route path='/mandir' element={<MainWrapper isImage={true} isTab={false}><Mandir /></MainWrapper>} />
        <Route path='/mandirDetail' element={<MainWrapper path={path.mandirDetail}><MandirDetail /></MainWrapper>} />
        <Route path='/roomDetail' element={<MainWrapper path={path.roomDetail}><RoomDetail /></MainWrapper>} />
        <Route path='/reset-password' element={<Resetpassword />} />
        {/* <Route path='/admin/*' element={<AdminRoute />} /> */}
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
