import React from "react";

export default function RoomData({img,alt,value,sup}){
    return(
        <>
            <div className="col-md-3 p-3 option d-flex align-items-center">
                <img src={img} alt={alt}/>
                <div className="desc">{value}{sup && <sup>{sup}</sup>}</div>
            </div>
        </>
    )
}